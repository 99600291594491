import instance from "utils/axios.utils";
import Functions from "utils/functions.utils";

const rateCardModel = {
  uploadDetails: (route_param : string,data:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = "/rate-card/"+route_param;
      instance()
        .post(url,data)
        .then((res) => {
          if(res.data.status){
            resolve(res.data);
          }else{
            reject("Something went wrong");
          }
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  fetchDetails: (storeId : string,) => {
    let promise = new Promise((resolve, reject) => {
      let url = "/rate-card?sId="+storeId;
      instance()
        .get(url)
        .then((res) => {
          if(res.data.status){
            resolve(res.data);
          }else{
            reject("Something went wrong");
          }
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
};

export default rateCardModel;
