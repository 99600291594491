import { useNavigate } from "react-router-dom";
import "./history.component.scss";

interface HistoryProps {
  name?: string;
  path?: string;
}

export default function History(props: HistoryProps) {
  const { name, path } = props;
  const navigate = useNavigate();

  const pathArray = path
    ? path.split("/")
    : window.location.pathname.split("/");
  name && (pathArray[pathArray.length] = name);

  return (
    <div className="view_dashboard_history_container">
      <div className="view_dashboard_history_wrapper">
        {pathArray.flat().map(
          (path, index) =>
            path !== "" && (
              <div
                className={`view_dashboard_history h5 ${
                  index === pathArray.length - 1 ? "unclickable" : ""
                }`}
                onClick={() => {
                  if (index !== pathArray.length - 1) {
                    navigate(-1);
                  }
                }}
                key={index}
              >
                {path.charAt?.(0)?.toUpperCase() +
                  path.replace("_", " ").substr(1)}
                {pathArray.length > index + 1 && (
                  <span className="gt_symbl">{">"}</span>
                )}
              </div>
            )
        )}
      </div>
    </div>
  );
}
