import instance from "utils/axios.utils";
import Functions from "utils/functions.utils";
import { setManyWarehouse, setWarehouse } from "utils/redux.utils";

const warehouse = {
  createWarehouse: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "warehouse";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  createPowerBills: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "warehouse/power-bills";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  createCharging: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "warehouse/charging";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  getManyWarehouse: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "warehouse";
        const response = await instance().get(url, { params: query });
        setManyWarehouse(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getManyCharger: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "warehouse/charging";
        const response = await instance().get(url, { params: query });
        setManyWarehouse(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getManyPower: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "warehouse/power-bills";
        const response = await instance().get(url, { params: query });
        setManyWarehouse(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getWarehouse: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "warehouse";
        const response = await instance().get(url, { params: id });
        setWarehouse(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getCharger: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "warehouse/charging";
        const response = await instance().get(url, { params: id });
        setWarehouse(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getPower: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "warehouse/power-bills";
        const response = await instance().get(url, { params: id });
        setWarehouse(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },

  editWarehouse: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "warehouse";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  editCharger: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "warehouse/charging";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  deleteWarehouse: (payload) => {
    let promise = new Promise((resolve, reject) => {
      let url = "warehouse/sa/drop";
      instance()
        .delete(url, { data: payload })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  exportWarehouse: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "warehouse?export=1";
        const response = await instance().get(url);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
};

export default warehouse;
