import testPic from "assets/images/user2.png";
import view from "assets/icons/eye.svg";
import edit from "assets/icons/edit.svg";
import delete_icon from "assets/icons/delete.svg";
import store from "assets/icons/store.svg";
import stores from "assets/icons/stores.svg";
import overview from "assets/icons/overview.svg";
import fullfily from "assets/icons/fullfily_icon.svg";
import close from "assets/icons/close.svg";
import image from "assets/icons/image.svg";
import area from "assets/icons/area.svg";
import driver from "assets/icons/driver.svg";
import hub from "assets/icons/hub.svg";
import booking from "assets/icons/order.svg";
import outsource from "assets/icons/outsource.svg";
import payout from "assets/icons/payout.svg";
import settings from "assets/icons/settings.svg";
import user from "assets/icons/user.svg";
import vehicle from "assets/icons/vehicle.svg";
import organization from "assets/icons/organization.svg";
import leftArrow from "assets/icons/left_arrow.svg";
import logout from "assets/icons/logout.svg";
import pickup from "assets/icons/pickup.svg";
import location from "assets/icons/location.svg";
import line from "assets/icons/line.svg";
import checked from "assets/icons/checked.svg";
import uncheck from "assets/icons/uncheck.svg";
import calender from "assets/icons/calender.svg";
import pin from "assets/images/pin.png";
import pin_2 from "assets/icons/pin_2.svg";
import download from "assets/images/download.png";
import qrcode from "assets/icons/qrcode.svg";
import fullfily_logo from "assets/images/fullfily_logo.png";
import fullfily_logo_blue from "assets/images/fullfily_logo_blue.png";
import pwd_view from "assets/icons/View.svg";
import pwd_hide from "assets/icons/View_hide.svg";
import profile from "assets/icons/profile.svg";
import two_wheeler from "assets/icons/2W-2.svg";
import three_wheeler from "assets/icons/3W-2.svg";
import four_wheeler from "assets/icons/4W-2.svg";
import pickup_address from "assets/icons/pickup_icon.svg";
import delivery_address from "assets/icons/delivery.svg";
import start from "assets/icons/start.svg";
import inactive from "assets/icons/inactive.svg";
import active from "assets/icons/active.svg";
import break_icon from "assets/icons/break.svg";
import pickup_marker from "assets/icons/pickup_marker.svg";
import delivery_marker from "assets/icons/delivery_icon.svg";
import city from "assets/icons/city_icon.svg";
import excel from "assets/icons/excel_icon.svg";
import filter_city from "assets/icons/filter_city.svg";
import own_vehicle from "assets/icons/2W-own.svg";
import dashboard from "assets/icons/dashboard.svg";
import client from "assets/icons/client.svg";
import inventory from "assets/icons/inventory.svg";
import career from "assets/icons/career.svg";
import performance from "assets/icons/performance.svg";
import expense from "assets/icons/expense.svg";
import checkin from "assets/icons/checkin.svg";
import approve from "assets/icons/approve.svg";
import decline from "assets/icons/decline.svg";
import onhold from "assets/icons/onhold.svg";
import ticket from "assets/icons/ticket.svg";
import arrowDown from "assets/icons/arrow-down.svg";
import arrowRight from "assets/icons/arrow-right.svg";
import email from "assets/icons/email.svg";
import call from "assets/icons/call.svg";
import bell from "assets/icons/bell.svg";
import file_placeholder from "assets/icons/file_placeholder_icon.svg";
import product from "assets/images/demo_product.png";
import product2 from "assets/images/demo_product2.png";
import map from "assets/images/map.png";
import fullfily_logo_green from "assets/images/fullfily_logo_green.png";
import fullfily_full_icon from "assets/icons/fullfily_full_icon.svg";
import fullfily_white_icon from "assets/icons/fullfily_white_icon.svg";
import archive from "assets/icons/archive.png";
import upload from "assets/icons/upload.png";
import access_control from "assets/icons/access-control.svg";
import charger from "assets/icons/charger.png";
import power from "assets/icons/power.png";
import refresh from "assets/icons/refresh.png";
const Assets = {
  testPic,
  edit,
  view,
  delete: delete_icon,
  store,
  stores,
  overview,
  fullfily,
  close,
  image,
  vehicle,
  hub,
  booking,
  outsource,
  settings,
  user,
  driver,
  payout,
  area,
  organization,
  leftArrow,
  logout,
  pickup,
  location,
  line,
  checked,
  uncheck,
  calender,
  pin,
  pin_2,
  download,
  qrcode,
  fullfily_logo,
  fullfily_logo_blue,
  pwd_hide,
  pwd_view,
  profile,
  two_wheeler,
  three_wheeler,
  four_wheeler,
  pickup_address,
  delivery_address,
  start,
  inactive,
  active,
  break_icon,
  pickup_marker,
  delivery_marker,
  city,
  excel,
  filter_city,
  own_vehicle,
  dashboard,
  client,
  inventory,
  expense,
  career,
  performance,
  checkin,
  approve,
  decline,
  onhold,
  ticket,
  arrowDown,
  arrowRight,
  email,
  call,
  bell,
  product,
  product2,
  map,
  fullfily_logo_green,
  fullfily_white_icon,
  fullfily_full_icon,
  file_placeholder,
  archive,
  upload,
  access_control,
  charger,
  power,
  refresh,
};

export default Assets;
