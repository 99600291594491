import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./view_driver.screen.scss";
import History from "components/history/history.component";
import View from "components/view/view.component";
import Assets from "imports/assets.import";
import { Models } from "utils/imports.utils";
import { toastify, toastifyError, useSetState } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";
import CustomModal from "common_components/ui/modal/modal.component";
import DeletePopup from "components/delete_popup/delete_popup.component";
import Avatar from "common_components/ui/avatar/avatar.ui";
import Divider from "common_components/ui/divider/divider.ui";
import moment from "moment";
import Button from "common_components/ui/button/button.ui";
import Pagination from "components/pagination/pagination.component";

export default function ViewDriver() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = useSetState({
    data: {},
    deleteModal: false,
    vehicleAllocated: [],
    deallocateModal: false,
    mappingConfig: [],
    currentPage: 1,
    totalItems: 0,
  });
  useEffect(() => {
    GetDriver();
  }, []);
  useEffect(() => {
    getAllocatedVehicle();
    getAllocatedClient()
  }, [state.dId]);
  useEffect(() => {
    GetDriverAllocationHistory();
  }, [state.dId,state.currentPage]);
  const GetDriverAllocationHistory = async () => {
    if(state.dId){
      try {
        const response: any = await Models.vehicle.getDriverAllocationHistory({
          id: state.dId,
          type: 'driver',
          page: state.currentPage,
          pageSize: 5,
        });
        setState({ driverAllocationHistory: response.data, totalItems: response?.count, });
      } catch (err) {
        console.log(err);
        // toastifyError(err);
      }
    }
  };
  const GetDriver = async () => {
    try {
      const response: any = await Models.driver.getDriver({
        id: id,
      });
      setState({ data: response.data[0], dId: response.data[0]._id });
    } catch (err) {
      console.log(err);
    }
  };
  const getAllocatedVehicle = async () => {
    try {
      let res: any;
      const body: any = {
        dId: state.dId,
      };
      res = await Models.vehicle.getAllocatedDriver(body);
      setState({ vehicleAllocated: res.data[0] });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  const inputFields: IAddValues[] = [
    { label: "Gender", key: "userId.gender", type: "string", isNested: true },
    { label: "D.O.B", key: "userId.dob", type: "date", isNested: true },
    {
      label: "Blood Group",
      key: "userId.bloodGroup",
      type: "string",
      isNested: true,
    },
    { label: "Mobile", key: "userId.mobile", type: "string", isNested: true },
    {
      label: "Whatsapp no.",
      key: "userId.wapNumber",
      type: "string",
      isNested: true,
    },
    {
      label: "Secondary no.",
      key: "userId.secondaryMobileNo",
      type: "string",
      isNested: true,
    },
    {
      label: "Father's Name",
      key: "userId.fatherName",
      type: "string",
      isNested: true,
    },
    {
      label: "State",
      key: "userId.address",
      secondaryKey: "state",
      type: "address",
      isNested: true,
    },
    {
      label: "City",
      key: "userId.address",
      secondaryKey: "city",
      type: "address",
      isNested: true,
    },
    {
      label: "Full address",
      key: "userId.address",
      secondaryKey: "fullAddress",
      type: "address",
      isNested: true,
    },
    {
      label: "Pincode",
      key: "userId.address",
      secondaryKey: "pinCode",
      type: "address",
      isNested: true,
    },
    {
      label: "Languages",
      key: "userId.languages",
      type: "array",
      isNested: true,
    },
    {
      label: "Remark",
      key: "userId.driverRegReqActionComment",
      type: "string",
      isNested: true,
    },
    // { label: "Aadhaar card no", key: "dAadhaarCardNumber", type: "string" },
    // { label: "Lisence no", key: "dDlNumber", type: "string" },
    // { label: "Pancard no", key: "dPanNumber", type: "string" },
    {
      label: "Aadhaar card front",
      key: "aadhaarCardFront",
      type: "passbook",
    },
    {
      label: "Aadhar card back",
      key: "aadhaarCardBack",
      type: "passbook",
    },
    {
      label: "Pancard front",
      key: "panCardFront",
      type: "passbook",
    },
    {
      label: "Pancard back",
      key: "panCardBack",
      type: "passbook",
    },
    {
      label: "Driving liscence front",
      key: "dlFront",
      type: "passbook",
    },
    {
      label: "Driving liscence back",
      key: "dlBack",
      type: "passbook",
    },
    {
      label: "Gas Bill",
      key: "gasBill",
      type: "passbook",
    },
    {
      label: "Account holder's name",
      key: "bankDetails.acHolderName",
      isNested: true,
      type: "string",
    },
    {
      label: "Account no.",
      key: "bankDetails.acNumber",
      isNested: true,
      type: "string",
    },
    {
      label: "IFSC Code ",
      key: "bankDetails.ifscCode",
      isNested: true,
      type: "string",
    },
    {
      label: "Passbook front",
      key: "bankDetails.passbookFront",
      isNested: true,
      type: "passbook",
    },
    {
      label: "Deposited amount",
      key: "driverDeposit",
      isNested: true,
      type: "string",
    },
    {
      label: "Deposited Remarks",
      key: "driverDepositRemarks",
      isNested: true,
      type: "string",
    },
    {
      label: "Contact Name (Emergency)",
      key: "emergencyDetails.name",
      isNested: true,
      type: "string",
    },
    {
      label: "Mobile (Emergency)",
      key: "emergencyDetails.mobile",
      isNested: true,
      type: "string",
    },
    {
      label: "City (Emergency)",
      key: "emergencyDetails.city",
      isNested: true,
      type: "string",
    },
    {
      label: "Address (Emergency)",
      key: "emergencyDetails.address",
      isNested: true,
      type: "string",
    },
    {
      label: "Pincode (Emergency)",
      key: "emergencyDetails.pinCode",
      isNested: true,
      type: "string",
    },
    {
      label: "Relation (Emergency)",
      key: "emergencyDetails.relation",
      isNested: true,
      type: "string",
    },
  ];
  const handleDelete = (data) => {
    setState({ id: data._id, deleteModal: true });
  };
  const DeleteDriver = async () => {
    try {
      await Models.driver.deleteDriver({
        ids: [id],
        target: "delete",
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: false,
      });
      setState({ id: "", deleteModal: false });
      navigate("/driver");
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const handleViewAllottedVehicle = () => {
    navigate(`/vehicle/view/${state?.vehicleAllocated?.vehicleId?._id}`);
  };
  const handleDeAllocation = async () => {
    try {
      const res: any = await Models.driver.deAllocation(
        state?.vehicleAllocated?._id
      );
      setState({ deallocateModal: false });
      toastify("Driver de-allocated successfully!");
      await GetDriver();
      await getAllocatedVehicle();
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const getAllocatedClient = async () => {
    if (state?.data?.userId?._id) {
      try {
        let res: any;
        const body: any = {
          dId: state?.data?.userId?._id,
        };
        res = await Models.driver.getAllocatedClient(body);
        setState({
          mappingConfig: res?.data?.assigned?.mappingConfig,
        });

      } catch (error) {
        setState({ loading: false });
        console.log(error);
        toastifyError(error);
      }
    }
  };
  return (
    <div className="view_driver_container">

      <div className="view_driver_wrapper">
        <History name={state?.data?.userId?.fullName} path="view" />
        <div className="view_container container-fluid p-5 pb-3">
          <h3>Allocated Clients</h3>
          <br />
          {/* Header */}
          <table width={"100%"} cellPadding={5}>
            <thead>
              <tr>
                <th>#</th>
                <th>Client</th>
                <th></th>
                <th>Store</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                state.mappingConfig?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.clientId?.fullName}</td>
                    <td><Button
                      onClick={() => navigate(`/client/view/${item?.clientId?.clientId}`)}
                      style={{ padding: "5px 10px" }} value="View client" /></td>
                    <td>{item?.storeId?.storeName}</td>
                    <td><Button
                      onClick={() => navigate(`/client/${item?.clientId?.clientId}/store/view/${item?.storeId?._id}`)}
                      style={{ padding: "5px 10px" }} value="View store" /></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <br />
        </div>
        <br />
        <div className="view_container container-fluid p-5 ">
          <h3>Vehicle Allocation History ({state?.driverAllocationHistory?.length ?? 0})</h3>
          <Divider />
          {/* Header */}
          <table width={"100%"} cellPadding={5}>
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Action</th>
                <th>Vehicle Number</th>
                <th>Assigned By</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                state?.driverAllocationHistory?.map((item, index) => (
                  <tr key={index} >
                    <td>{index + 1}</td>
                    <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                    <td>{item?.actionName}</td>
                    <td>{item?.vehicleId?.vhNumber}</td>
                    <td>
                      <b>Email : </b> {item?.createdBy?.email}
                    </td>
                    <td className="text-center">
                      {
                        item?.assignedTo?._id ?
                          <Button
                            onClick={() => navigate(`/vehicle/view/${item?.vehicleId?._id}`)}
                            style={{ padding: "5px 10px" }} value="View vehicle" />
                          : "NA"
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className="driver_pagination">
          <Pagination
            totalItems={state.totalItems}
            itemsPerPage={5}
            currentPage={state.currentPage}
            onPageChange={(page) => setState({ currentPage: page })}
          />
        </div>
        <br />
        <div className="view_driver_body_container">
          <View
            actions={[
              { key: "DRIVER_EDIT", link: `/driver/edit/${id}`, icon: "edit" },
            ]}
            data={state.data}
            values={inputFields}
            head={<ViewHeader />}
            // buttons={<ViewButton />}
            hasFiles
          />
        </div>
      </div>
      <div className="view_container container-fluid p-5 pb-3">
        <h3>Driver action history</h3>
        <br />
        {/* Header */}
        <div className="row">
          <h5 className="col-1">Action</h5>
          <h5 className="col-2">Date </h5>
          <h5 className="col-3">Status</h5>
          <h5 className="col-5">Performed by</h5>
        </div>
        <Divider />
        {/* Rows */}
        {
          state.data?.adminActionHistory?.map((item, index) => (
            <div className="row my-4">
              <div className="col-1">{item.actionType}</div>
              <div className="col-2">{moment(item?.actionPerformedAt).format("DD-MM-YYYY, h:mm a")}</div>
              <div className="col-3">{item.actionStatement}</div>
              <div className="col-5">
                <div className="d-flex align-items-center">
                  <Avatar
                    name="Super Admin"
                    size={50}
                    imageSrc={item?.performedBy?.userImage}
                  />
                  <div className="mx-3">
                    <div><strong style={{ width: 70, display: 'inline-block' }} >Email :</strong>  {item?.performedBy?.email}</div>
                    <div><strong style={{ width: 70, display: 'inline-block' }}> Mobile :</strong>  {item?.performedBy?.mobile}</div>
                  </div>
                </div>
              </div>
              {
                index !== state.data?.adminActionHistory?.length - 1 &&
                <Divider />
              }
            </div>
          ))
        }
      </div>
      <br />
      <br />
      <br />
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      >
        <DeletePopup
          onPress={DeleteDriver}
          onCancel={() => setState({ deleteModal: false })}
        />
      </CustomModal>
    </div>
  );
  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_image_conatiner">
          <img
            className="view_head_image"
            src={state?.data?.userId?.userImage || Assets.testPic}
            alt="head_image"
          />
        </div>
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">
              {state?.data?.userId?.fullName}
            </div>
            {state.vehicleAllocated && (
              <div className="allocated_to">
                Allocated to - {state.vehicleAllocated?.vehicleId?.vhNumber}{" "}
                <img
                  src={Assets["view"]}
                  onClick={() => handleViewAllottedVehicle()}
                />
                <img
                  onClick={() => setState({ deallocateModal: true })}
                  src={Assets["delete"]}
                />
              </div>
            )}
          </div>
        </div>
        <CustomModal
          center
          open={state.deallocateModal}
          classNames={{ modalContainer: "delete_modal_container" }}
          onClose={() => setState({ deallocateModal: false })}
        >
          <div className="reject_modal_container">
            <div className="reject_heading">
              Are you sure you want to deallocate vehicle?
            </div>
            <div className="reject_modal_button">
              <div
                onClick={() => {
                  setState({ deallocateModal: false });
                }}
                className="reject_cancel_button"
              >
                No
              </div>
              <div
                onClick={() => handleDeAllocation()}
                className="reject_button"
              >
                Yes
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    );
  }
}
