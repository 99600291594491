import React, { useEffect } from "react";
import ListComponent from "components/list/list.component";
import { useSetState } from "utils/functions.utils";
import "./dashboard.scss";
import "react-responsive-modal/styles.css";
import { Models } from "utils/imports.utils";
import { autoRefreshTime } from "utils/constant.utils";
import _ from "lodash";
// import { ROLES } from "constants/user.constant";
import CircularBar from "components/circular_progress_bar/circular_bar.component";
import IconTable from "components/icon_table/icon_table";
import Search from "common_components/ui/search/search.ui";
// import OrderTrack from "components/order_track/order_track.component";
// import OrderTrackDashboard from "components/order_track_dashboard/order_track_dashboard.component";
import { useNavigate } from "react-router-dom";
import Button from "common_components/ui/button/button.ui";

interface IUserDetails {
  email: string;
  name: string;
}

export default function Dashboard() {
  // localStorage
  let city: any = localStorage.getItem("city");
  city = JSON.parse(city);
  const navigate = useNavigate();
  //state
  const [state, setState] = useSetState({
    data: [],
    search: "",
    openModal: false,
    viewModal: false,
    liveOrderModal: false,
    deleteModal: false,
    loading: false,
    order_data: [],
    heads: {
      bookings: { head: "Total orders", value: "0" },
      organizations: { head: "Delivered", value: "0" },
      stores: { head: "Processing", value: "0" },
      vehicles: { head: "Cancelled", value: "0" },
    },

    booking_chart: {
      keys: [],
      values: [],
    },
    driver_chart: {
      keys: [],
      values: [],
    },
    timeout: 0,
  });

  useEffect(() => {
    // dashboard();
    // const role = localStorage.getItem("role");
    // if (role === ROLES.ADMIN) {
    //   localStorage.removeItem("org");
    // }
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      setState({ timeout: Math.random() });
    }, autoRefreshTime);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dashboard();
  }, [state.timeout]);
  useEffect(() => {
    dashboard();
  }, [state.search]);

  const dashboard = async () => {
    const body: any = {
      search: state.search,
    };
    try {
      // const res: any = await Models.user.dashboard();
      // const res2: any = await Models.dashboard.getManyDashboardOrderData(body);
      // console.log(res2.data);
      // setState({ data: res?.data, order_data: res2.data.docs });
      // const heads = {
      //   bookings: { head: "Total orders", value: res.data?.achievements?.total_order },
      //   drivers: { head: "Delivered", value: res.data?.achievements?.orderData?.[0]?.count },
      //   organizations: { head: "Processing", value: res.data?.achievements?.orderData?.[1]?.count },
      //   stores: { head: "Cancelled", value: res.data?.achievements?.orderData?.[2]?.count },
      // };
      // setState({ heads: heads });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
    }
  };

  return (
    <div className="dashboard_container">
      <div className="list_wrapper">
        <div className="dashboard-header">
          <h1 className="head h5">Today's achievements</h1>
        </div>
        <ListComponent
          data={state.data}
          loading={state.loading}
          color={"#52C1FF"}
          delete_icon_color={"delete_btn_color"}
          delete={async (id: string) => {
            setState({ id, deleteModal: true, edit: false });
          }}
          theads={Object.values(state.heads)}
          link="user"
          percent={"89"}
        />
        <div className="head h5">Current Status</div>
        <div className="progress-wrapper">
          <CircularBar
            percentage={"89"}
            color={"#7F52FF"}
            title={"Total orders"}
            count={"134"}
            onClick={() => navigate("/order")}
          />
          <CircularBar
            percentage={"50"}
            color={"#FFDCDC"}
            title={"Available drivers"}
            count={"55"}
            onClick={() => navigate("/driver")}
          />
          <CircularBar
            percentage={"89"}
            color={"#FFCF52"}
            title={"Available vehicles"}
            count={"60"}
            onClick={() => navigate("/vehicle")}
          />
        </div>
        <div className="head h5">Overall info</div>
        <div className="overall-info-container">
          <div className="overall-left">
            <IconTable
              icon="driver"
              title="Total drivers"
              count={"134"}
              color={"#F78A6B"}
              onClick={() => navigate("/driver")}
            />
            <IconTable
              icon="organization"
              title="Total organizations"
              count={"15"}
              color={"#FEBC65"}
              onClick={() => navigate("/client")}
            />
            <IconTable
              icon="hub"
              title="Total hubs"
              count={"22"}
              color={"#82E8F4"}
              onClick={() => navigate("/hub")}
            />
          </div>
          <div className="overall-left">
            <IconTable
              icon="vehicle"
              title="Total vehicles"
              count={"212"}
              color={"#51449E"}
              onClick={() => navigate("/store")}
            />
            <IconTable
              icon="store"
              title="Total stores"
              count={"123"}
              color={"#DDF1F5"}
              onClick={() => navigate("/employee")}
            />
            <IconTable
              icon="user"
              title="Total users"
              count={"12"}
              color={"#40B3F3"}
              onClick={() => navigate("/vehicle")}
            />
          </div>
        </div>
      </div>
      {/* {state.liveOrderModal && (
        <div className="dashboard_screen_right">
          <div className="dashboard_screen_right_header">
            <div className="head h5">Live Orders</div>
            <div className="close" onClick={() => setState({ liveOrderModal: false })}>
              X
            </div>
          </div>
          <div className="scrollable">
            {state?.order_data?.map(item => {
            })}
          </div>
        </div>
      )} */}
    </div>
  );
}
