import instance from "utils/axios.utils";
import encrypt from "utils/encrypt";
import Functions from "utils/functions.utils";
const app_env = process.env.REACT_APP_ENVIRONMENT
const Store = {
  loginWithOtp: (sid) => {
    let promise = new Promise((resolve, reject) => {
      let config = {}
      if( app_env== 'development'){
        config = {
          headers : {
            source : "dev.fullfily.com"
          }
        }
      }
      let url = "store/access?sid=" + sid;
      instance()
        .post(url,{},config)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  verifyStoreOTP: (data:{sid:string,otp : string}) => {
    let promise = new Promise(async(resolve, reject) => {
      const jsonString = JSON.stringify(data);
      const enc_data = await encrypt(jsonString);
      let url = "/store/access-validate";
      instance()
        .post(url,{data:enc_data})
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  createStore: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "store";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  createArea: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "area/create_area";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  editStore: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "store";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  getStore: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "store";
        const response = await instance().get(url, { params: query });
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getAllocatedDriver: (sid:string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `clients/available-drivers/stats/store/${sid}`;
        const response = await instance().get(url);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getManyStore: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "store";
        const response = await instance().get(url, { params: query });
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getManyArea: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "area/get_many_area";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  deleteStore: (payload) => {
    let promise = new Promise((resolve, reject) => {
      let url = "store/sa/drop";
      instance()
        .delete(url, { data: payload })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  exportStore: (clientId : string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `store?client=${clientId}&export=1`;
        const response = await instance().get(url);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
};

export default Store;
