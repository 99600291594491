import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { ACCESS_CONTROL_KEYS_TYPE } from "screens/access_control/data";

export interface AccessControlWrapperProps
  extends React.HTMLAttributes<HTMLDivElement> {
  accessKey?: ACCESS_CONTROL_KEYS_TYPE;
  isModule?: boolean;
  moduleKey?: string;
}

const AccessControlWrapper: React.FC<AccessControlWrapperProps> = ({
  accessKey = "",
  isModule = false,
  moduleKey = "",
  children,
  ...rest
}) => {
  const ACL: string[] = useSelector(
    (s: any) => s.accessControl?.data?.activeACL
  );
  const role = localStorage.getItem("role") + "";
  if ((role === "SA") && isModule) return <Outlet />;
  else if ((role === "SA"  || accessKey == 'IGNORE')&& !isModule) return <>{children}</>;
  if (!isModule && ACL.includes(accessKey)) return <>{children}</>;
  else if (
    isModule &&
    ACL.filter((str) => str.includes(moduleKey)).length !== 0
  )
    return <Outlet />;

  return null;
};

export default AccessControlWrapper;
