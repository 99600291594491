import React, { useEffect, useState } from "react";
import {
  useSetState,
  toastifyError,
  createExcelFile,
  toastify,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import "./organization.screen.scss";
import "react-responsive-modal/styles.css";
import DeletePopup from "components/delete_popup/delete_popup.component";
import Table from "components/table/table.component";
import Button from "common_components/ui/button/button.ui";
import Search from "common_components/ui/search/search.ui";
import CustomModal from "common_components/ui/modal/modal.component";
import { useNavigate } from "react-router-dom";
import { setOrganization } from "utils/redux.utils";
import _ from "lodash";
import Pagination from "components/pagination/pagination.component";
import pako from "pako";
import { Buffer } from "buffer";
import Colors from "imports/color.import";
import TextareaComponent from "common_components/ui/text_area/textarea.component";
import AccessControlWrapper from "common_components/access_control.wrapper.hoc";

export default function Organization() {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  //state
  const [state, setState] = useSetState({
    data: [],
    search: "",
    currentPage: 1,
    totalItems: 0,
    openModal: false,
    viewModal: false,
    deleteModal: false,
    loading: true,
    isOpenModel: false,
    toggleFilter: false,
    filterSize: "",
    filterType: "",
    filterClient: "",
    clientData: [],
    activeTab: "",
    selectedFilter: "ls",
  });

  const size = [
    { label: "Small", value: "small" },
    { label: "Medium", value: "medium" },
    { label: "Large", value: "large" },
    { label: "All sizes", value: 0 },
  ];
  const type = [
    { label: "DYNAMIC", value: "DYNAMIC" },
    { label: "DEDICATED", value: "DEDICATED" },
    { label: "All types", value: 0 },
  ];
  const thead = [
    { head: "Name", key: "fullName" },
    { head: "Date", key: "createdAt", type: "date-only" },
    { head: "Mobile", key: "mobile" },
    { head: "Email", key: "email" },
    { head: "Address", key: "address[0].fullAddress", isNested: true },
    { head: "GST", key: "clientId.gstNo", isNested: true },
    {
      head: "Contract start date",
      key: "clientId.contractStartDate",
      type: "date-only",
      isNested: true,
    },
    {
      head: "Contract end date",
      key: "clientId.contractEndDate",
      type: "date-only",
      isNested: true,
    },
    { head: "Type", key: "clientId.type", isNested: true },
  ];

  // hooks
  useEffect(() => {
    GetManyData();
  }, [
    state.search,
    state.dateRange,
    state.filterSize,
    state.filterType,
    state.currentPage,
    state.filterClient,
    state.selectedFilter,
  ]);

  const GetManyData = async () => {
    try {
      let res: any;
      const body: any = {
        page: state.currentPage,
        pageSize: 10,
      };
      if (state.search?.length > 0) {
        body.search = state.search;
      
      }
      if (state.filterSize?.length > 0) {
        body.size = state.filterSize;
      }
      if (state.filterType?.length > 0) {
        body.type = state.filterType;
      }
      if (state.filterClient?.length > 0) {
        body.userId = state.filterClient;
      }
      if (state.selectedFilter.length > 0) {
        body.clt = state.selectedFilter;
      }
      res = await Models.organization.getManyOrganization(body);
      setState({
        totalItems: res?.count,
        data: res?.data,
      });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  // const getAllClient = async () => {
  //   let data: any = [];
  //   try {
  //     const res: any = await Models.organization.getManyOrganization({});
  //     for (let client of res.data) {
  //       data.push({
  //         label:
  //           client?.fullName && client?.clientId?.userId
  //             ? `${client?.fullName} - ${client?.clientId?.userId}`
  //             : client?.fullName || client?.clientId?.userId,
  //         value: client?.clientId?.userId,
  //       });
  //     }
  //     data = _.orderBy(data, ["label"]);
  //     data.unshift({
  //       label: "ALL CLIENTS",
  //       value: 0,
  //     });
  //     setState({ clientData: _.uniqBy(data, "value") });
  //   } catch (err: any) {
  //     console.log("err", err);
  //   }
  // };
  // useEffect(() => {
  //   getAllClient();
  // }, []);
  const DeleteOrganization = async () => {
    try {
      await Models.organization.deleteOrganization({
        ids: [state.id],
        target: "delete",
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: false,
      });
      setState({ id: "", deleteModal: false });
      GetManyData();
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const handleView = (data) => {
    navigate(`/client/view/${data?.clientId?._id}`);
  };
  const handleDelete = (data) => {
    setState({ id: data?.clientId?._id, deleteModal: true });
  };
  const navigateToStore = (data) => {
    setOrganization(data);
    navigate(`/client/${data?.clientId?._id}/store`);
  };
  const handlePageChange = (page: number) => {
    setState({ currentPage: page });
  };
  const handleFilter = (status) => {
    setState({ selectedFilter: status, currentPage: 1, totalItems: 0 });
  };
  const handleApproval = (data: any) => {
    setState({ user_id: data._id, approvalModal: true });
  };
  const handleApproveStatus = async (uId) => {
    try {
      await Models.user.approveUser(uId);
      GetManyData();
      toastify("Client status updated");
      setState({
        approvalModal: false,
      });
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const handleRejectStatus = async (uId) => {
    if (!_.isEmpty(state.comment)) {
      try {
        const body = {
          uId,
          comment: state.comment,
        };
        await Models.user.rejectUser(body);
        GetManyData();
        toastify("User status updated");
        setState({
          rejectModal: false,
          comment: "",
        });
      } catch (err) {
        toastifyError(err);
        console.log(err);
      }
    } else {
      toastifyError("Please enter a reasons");
    }
  };
  const handleExport = async () => {
    const res: any = await Models.organization.exportOrg();
    const compressedString = res.data;
    const compressedBuffer = Buffer.from(compressedString, "base64");
    const base64String = pako.ungzip(compressedBuffer, { to: "base64" });
    createExcelFile(base64String);
  };
  return (
    <div className="driver_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="head h3">Client</div>
        </div>
        <div className="search_wrapper">
          <Search
            value={state.search}
            onChange={(search) => setState({ search ,currentPage: 1, totalItems: 0 })}
            placeholder={"Search by Name, Mobile"}
          />

          <div className="button_group">
            {/* <Filter
              onClick={() => setState({ toggleFilter: !state.toggleFilter })}
            /> */}
            <AccessControlWrapper accessKey="CLIENT_ADD">
              <Button
                value="New Client"
                boxIcon="bx-plus"
                onClick={() => {
                  navigate("/client/add");
                }}
              />
            </AccessControlWrapper>
            <AccessControlWrapper accessKey="CLIENT_EXPORT">
              <Button
                value="Export"
                onClick={() => {
                  handleExport();
                }}
              />
            </AccessControlWrapper>
          </div>
        </div>
      </div>
      {/* <div className="all_filter_container">
        {state.toggleFilter && (
          <div className="all_filter_dropdown_wrapper">
            <div className="all_filter_dropdown_item">
              <SelectDropdown
                notfound={"Not found"}
                placeholder={"Size"}
                data={size}
                onChange={(filterSize: any) =>
                  setState({
                    filterSize: filterSize.value,
                    currentPage: 1,
                    totalItems: 0,
                  })
                }
                value={state.filterSize}
              />
            </div>
            <div className="all_filter_dropdown_item">
              <SelectDropdown
                notfound={"Not found"}
                placeholder={"Type"}
                data={type}
                onChange={(filterType: any) =>
                  setState({
                    filterType: filterType.value,
                    currentPage: 1,
                    totalItems: 0,
                  })
                }
                value={state.filterType}
              />
            </div>
            <div className="all_filter_dropdown_item">
              <SelectDropdown
                notfound={"Not found"}
                placeholder={"Client name - userId"}
                data={state.clientData}
                onChange={(filterClient: any) =>
                  setState({
                    filterClient: filterClient.value,
                    currentPage: 1,
                    totalItems: 0,
                  })
                }
                value={state.filterClient}
              />
            </div>
          </div>
        )}
      </div> */}
      <div className="driver_filters">
        <div
          onClick={() => handleFilter("ls")}
          className={`button ${
            "ls" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Approved
        </div>
        <div
          onClick={() => handleFilter("wt")}
          className={`button ${
            "wt" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Requested
        </div>
      </div>
      <div className="drivertable">
        <Table
          data={state.data}
          totalDocs={state.totalDocs}
          loading={state.loading}
          theads={thead}
          link=""
          loadMore={() => {}}
          actions={
            state.selectedFilter === "ls"
              ? [
                  {
                    key: "CLIENT_STORE_VIEW",
                    icon: "store",
                    onClick: navigateToStore,
                  },
                  {
                    key: "CLIENT_VIEW",
                    icon: "view",
                    onClick: handleView,
                  },

                  {
                    key: "CLIENT_DELETE",
                    icon: "delete",
                    onClick: handleDelete,
                  },
                ]
              : state.selectedFilter === "wt"
              ? [
                  {
                    key: "CLIENT_VIEW",
                    icon: "store",
                    onClick: navigateToStore,
                  },
                  {
                    key: "CLIENT_VIEW",
                    icon: "view",
                    onClick: handleView,
                  },
                  {
                    key: "CLIENT_DELETE",
                    icon: "delete",
                    onClick: handleDelete,
                  },
                  {
                    key: "CLIENT_APPROVE",
                    icon: "delete",
                    text: "Approve",
                    textBackground: Colors.primary,
                    hideIcon: true,
                    onClick: handleApproval,
                  },
                ]
              : []
          }
          imageKey="userImage"
          img={true}
        />
      </div>
      <div className="driver_pagination">
        <Pagination
          totalItems={state.totalItems}
          itemsPerPage={10}
          currentPage={state.currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      >
        <DeletePopup
          onPress={DeleteOrganization}
          onCancel={() => setState({ deleteModal: false })}
        />
      </CustomModal>
      {/* approve modal */}
      <CustomModal
        center
        open={state.approvalModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ approvalModal: false })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">
            Are you sure you want to approve?
          </div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ approvalModal: false });
              }}
              className="reject_cancel_button"
            >
              Cancel
            </div>
            <div
              onClick={() => handleApproveStatus(state.user_id)}
              className="reject_button"
            >
              Approve
            </div>
          </div>
        </div>
      </CustomModal>
      {/* approve modal end */}
      {/* reject Modal */}
      <CustomModal
        center
        open={state.rejectModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ rejectModal: false, comment: "" })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">Reason for rejection</div>
          <div className="rejection_document_wrapper">
            <TextareaComponent
              placeholder="Enter rejection reasons"
              value={state.comment}
              onChange={(comment: any) => setState({ comment })}
            />
          </div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ rejectModal: false, comment: "" });
              }}
              className="reject_cancel_button"
            >
              Cancel
            </div>
            <div
              onClick={() => handleRejectStatus(state.user_id)}
              className="reject_button"
            >
              Save
            </div>
          </div>
        </div>
      </CustomModal>
      {/* reject modal end */}
    </div>
  );
}
