import { createAsyncThunk } from "@reduxjs/toolkit";
import Models from "imports/models.import";

export const getAccessControls = createAsyncThunk("access_controls/get",
    async (user_id : string, { rejectWithValue, dispatch,getState }) => {
        try {
            if (user_id ) {
                const res:any =  await Models.accessControl.getAccessControlInSAMode({
                  userId: user_id,
                });
                return {
                    data: res?.data,
                }
            } else {
                return rejectWithValue("User id retrieval failed");
            }
        } catch (error:any) {
            return rejectWithValue(error.message);
        }
    }
)