import { Editor as PREditor , EditorProps as PREditorProps} from 'primereact/editor'
import React, { FC } from 'react'

export interface EditorProps extends PREditorProps {
  onChange : (html)=>void ;
  label? : string;
  optional? : boolean
}

const Editor:FC<EditorProps> = ({
  onTextChange,
  onChange,
  label,
  optional = false,
  ...rest
}) => {
  return (
    <div className="input_container">
      <div className="label_container caption2">
        {label}
        {!optional && <span className="required">*</span>}
      </div>
      {/* @ts-ignore */}
      <PREditor onTextChange={(e) => onChange(e.htmlValue)} {...rest} />
    </div>
  )
}

export default Editor