import Button from "common_components/ui/button/button.ui";
import Error from "common_components/ui/error/error.ui";
import InputField from "common_components/ui/field/field.ui";
import FileInput from "common_components/ui/file_input/file_input.ui";
import SelectDropdown from "common_components/ui/select_dropdown/select_dropdown.component";
import { ErrorMessage, Field, Formik, useFormik } from "formik";
import Colors from "imports/color.import";
import Models from "imports/models.import";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { POWER_BILLS_UPLOAD_URL } from "utils/constant.utils";
import {
  removeEmptyValues,
  toastifyError,
  useSetState,
} from "utils/functions.utils";
import { Validation } from "utils/imports.utils";


export type powerBill ={
  _id?: string,
  monthFor : string,
  billNumber: string,
  initialReadings: string,
  billDocument: any,
  paidAmount: number,
}
export interface PowerBillProps extends React.HTMLAttributes<HTMLDivElement> {
  month: string,
  data : powerBill
}
const PowerBill = forwardRef((props: PowerBillProps, ref) => {
  const { month ,data } = props
  const isEditable = false;
  const initialValues = Object.keys(data).length >= 4 ? data :  {
    monthFor: month,
    billNumber: "",
    initialReadings: "",
    billDocument: null,
    paidAmount: null,
    
  };

  const [state, setState] = useSetState({
    data: initialValues,
    loading: false,
    buttonDisabled: false,
  });
  const onSubmit = (values: typeof initialValues) => {
    // console.log("Wohhoooo!",values)
  }
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: Validation.powerBill,
    enableReinitialize : true
  })
  const isFormTouched = ()=>{
    const {billDocument ,billNumber , initialReadings , paidAmount} = formik.values
    const isFormTouched  = billDocument || billNumber || initialReadings || paidAmount ;
    console.log((billDocument || billNumber || initialReadings || paidAmount) ? true : false);
    
    if (isFormTouched) return true;
    return false
  }
  useImperativeHandle(ref, () => ({
    submitForm: formik.handleSubmit,
    // isValid : isFormTouched() ? formik.isValid : null,
    values : formik.values
  }), [formik.values])
  const getErrorMsg = (key)=>{
    const msg =  (formik.touched[key] && key in formik.errors) ?  formik.errors[key] : undefined
    if (msg) return <Error >{msg}</Error>
    return null
  }
  const clearData = ()=>{
    formik.resetForm()
  }
  return (
    <div className="add_power_wrapper">
      <div className="d-flex">
        <h5 style={{ marginBottom: 15,flex : 1 }}>{month}</h5>
          <i onClick={clearData} style={{cursor : "pointer"}} className='bx bx-sm bx-x-circle text-danger' ></i>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <label htmlFor="" className="input-label">Bill number <span className="text-danger">*</span> </label>
            <InputText
              style={{ width: "100%" }}
              value={formik.values.billNumber}
              onChange={(e) => formik.setFieldValue("billNumber", e.target.value)}
              onBlur={()=>formik.setFieldTouched("billNumber")}
              placeholder="Enter bill number"
            />
            {getErrorMsg("billNumber")}
          </div>
          <div className="col-md-3">
            <label htmlFor="" className="input-label">Initial Readings<span className="text-danger">*</span> </label>
            <InputText
              style={{ width: "100%" }}
              value={formik.values.initialReadings}
              onChange={(e) => formik.setFieldValue("initialReadings", e.target.value)}
              onBlur={()=>formik.setFieldTouched("initialReadings")}
              placeholder="Enter initial readings"
            />
            {getErrorMsg("initialReadings")}
          </div>
          <div className="col-md-3">
            <label htmlFor="" className="input-label">Paid amount<span className="text-danger">*</span> </label>
            <InputNumber
              style={{ width: "100%" }}
              value={formik.values.paidAmount}
              onChange={(e) => formik.setFieldValue("paidAmount", e.value)}
              onBlur={()=>formik.setFieldTouched("paidAmount")}
              placeholder="Enter paid amount"
            />
            {getErrorMsg("paidAmount")}
          </div>
          <div className="col-md-3">
            <label htmlFor="" className="input-label">Bill Document<span className="text-danger">*</span> </label>
            <FileInput
              value={formik.values.billDocument}
              style={{padding : 8 , borderWidth : 1 , borderRadius : 5}}
              fileKey={month + "inputUpload"}
              fileType = {'file'}
              accessType="private"
              name={"billDocument"}
              file_bucket_url={POWER_BILLS_UPLOAD_URL}
              onChangeFile={(file) => {
                console.log(file);

                formik.setFieldValue("billDocument", file);
              }}
              // optional={optional}
            />
            {getErrorMsg("billDocument")}
          </div>
        </div>
      </div>
    </div>
  );
}
)

export default PowerBill;
