import { ErrorMessage, Field } from "formik";
import "./field.ui.scss";
import ValidationError from "../error/error.ui";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSetState } from "utils/functions.utils";
import Assets from "imports/assets.import";
import DateRangePicker from "../date_range_picker/date_range.component";
interface InputProps {
  className?: string;
  label?: string;
  type:
    | "text"
    | "password"
    | "number"
    | "textarea"
    | "date"
    | "birthdate"
    | "future_date"
    | "dateRange"
    | "array"
    | "address"
    | "year";
  placeholder?: string;
  name: string;
  secondName?: string;
  value?: string;
  id?: string;
  disabled?: boolean;
  onChange?: any;
  autocomplete?: boolean;
  additionalProps?: any;
  optional?: boolean;
}

const InputField = (props: InputProps) => {
  const [state, setState] = useSetState({ isPwdShow: false, dateRange: "" });
  const {
    label,
    type,
    name,
    className,
    placeholder,
    // value,
    id,
    disabled = false,
    autocomplete = true,
    onChange,
    optional = false,
    additionalProps,
    ...rest
  } = props;

  return (
    <div className="input_container">
      <div className="label_container caption2">
        {label}
        {!optional && <span className="required">*</span>}
      </div>
      {type === "text" && (
        <div className="field_wrap">
          <Field
            autocomplete={autocomplete ? "on" : "off"}
            disabled={disabled}
            name={name}
            type={type}
            className={`${className} input menu`}
            autoComplete="off"
            placeholder={
              placeholder && placeholder.length !== 0
                ? placeholder
                : "Please enter " + label?.toLowerCase()
            }
            {...additionalProps}
            {...(onChange ? { onChange } : {})}
            {...rest}
          />
          <ErrorMessage name={name} component={ValidationError} />
        </div>
      )}
      {type === "password" && (
        <div className="field_wrap">
          <div className="password_wrap">
            <Field
              name={name}
              type={state.isPwdShow ? "text" : "password"}
              className={`${className} input menu`}
              autoComplete="off"
              placeholder={
                placeholder && placeholder.length !== 0
                  ? placeholder
                  : "Please enter " + label?.toLowerCase()
              }
              // {...rest}
            />
            <div
              className="pwd_icon"
              onClick={() => setState({ isPwdShow: !state.isPwdShow })}
            >
              {state.isPwdShow ? (
                <img src={Assets.pwd_view} />
              ) : (
                <img src={Assets.pwd_hide} />
              )}
            </div>
          </div>
          <ErrorMessage name={name} component={ValidationError} />
        </div>
      )}
      {type === "number" && (
        <div className="field_wrap">
          <Field
            id={id}
            name={name}
            type={type}
            className={`${className} input menu`}
            autoComplete="off"
            placeholder={
              placeholder && placeholder.length !== 0
                ? placeholder
                : "Please enter " + label?.toLowerCase()
            }
            {...rest}
          />
          <ErrorMessage name={name} component={ValidationError} />
        </div>
      )}
      {type === "array" && (
        <div className="field_wrap">
          <Field name={name} {...rest}>
            {({ field, form }) => {
              const handleArrayChange = (event) => {
                const { value } = event.target;
                const array = value.split(",").map((item) => item.trim());
                form.setFieldValue(name, array);
              };

              return (
                <input
                  className={`${className} input menu`}
                  type="text"
                  {...field}
                  onChange={handleArrayChange}
                  placeholder={`Values separated by commas`}
                />
              );
            }}
          </Field>
          <ErrorMessage name={name} component={ValidationError} />
        </div>
      )}

      {type === "textarea" && (
        <div className="field_wrap">
          <Field
            as="textarea"
            name={name}
            type="textarea"
            className={`${className} input input_textarea menu`}
            placeholder={
              placeholder && placeholder.length !== 0
                ? placeholder
                : "Please enter " + label?.toLowerCase()
            }
            {...rest}
          />
          <ErrorMessage name={name} component={ValidationError} />
        </div>
      )}
      {type === "date" && (
        <div className="field_wrap">
          <Field name={name} {...rest}>
            {({ form, field }) => {
              let date = field.value ? new Date(field.value) : null;
              return (
                <DatePicker
                  selected={date}
                  placeholderText="Select a date"
                  dateFormat="MMMM d, yyyy"
                  className="date-picker w-100"
                  name={name}
                  onChange={(date) => {
                    const isoString = date ? date.toISOString() : null;
                    form.setFieldValue(name, isoString);
                  }}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                />
              );
            }}
          </Field>
          <ErrorMessage name={name} component={ValidationError} />
        </div>
      )}
      {type === "year" && (
        <div className="field_wrap">
          <Field name={name} {...rest}>
            {({ form, field }) => (
              <select
                className="date-picker w-100"
                value={field.value}
                onChange={(e) => form.setFieldValue(name, e.target.value)}
              >
                <option value="">Select a year</option>
                {Array.from({ length: 10 }, (_, index) => {
                  const year = new Date().getFullYear() - index;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            )}
          </Field>
          <ErrorMessage name={name} component={ValidationError} />
        </div>
      )}

      {(type === "birthdate" || type === "future_date") && (
        <div className="field_wrap">
          <Field name={name} {...rest}>
            {({ form, field }) => {
              let date = field.value ? new Date(field.value) : null;
              return (
                <DatePicker
                  selected={date}
                  placeholderText="Select a date"
                  dateFormat="MMMM d, yyyy"
                  className="date-picker w-100"
                  name={name}
                  onChange={(date) => {
                    const isoString = date ? date.toISOString() : null;
                    form.setFieldValue(name, isoString);
                  }}
                  maxDate={
                    type === "future_date" ? new Date("2050/01/01") : new Date()
                  }
                  minDate={
                    type === "future_date" ? new Date() : new Date("1950/01/01")
                  }
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                />
              );
            }}
          </Field>
          <ErrorMessage name={name} component={ValidationError} />
        </div>
      )}

      {type === "dateRange" && (
        <div className="field_wrap">
          <Field name={name} {...rest}>
            {({ form, field }) => {
              const selectedRange = field.value || null;

              return (
                <div>
                  <DateRangePicker
                    value={selectedRange}
                    onChange={(newRange) => {
                      form.setFieldValue(name, newRange);
                    }}
                  />
                </div>
              );
            }}
          </Field>
          <ErrorMessage name={name} component={ValidationError} />
        </div>
      )}
    </div>
  );
};

InputField.defaultProps = {
  className: "",
  label: "",
  placeholder: "",
};

export default InputField;
