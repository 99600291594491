import { toast } from "react-toastify";
import instance from "utils/axios.utils";
import { modelError, toastifyError } from "utils/functions.utils";

const auth = {
  register: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/register";
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error?.response?.data?.error?.errors[0]?.display_msg);
          if (error?.response?.data?.error?.errors[0]?.display_msg) {
            toastifyError(error?.response?.data?.error?.errors[0]?.display_msg)
            reject(error?.response?.data?.error?.errors[0]?.display_msg);
          } else {
            toastifyError(modelError(error))
            reject(error);
          }
        });
    });
    return promise;
  },
  login: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/login";
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  forgotPassword1: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/forgotPassword";
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  forgotPassword2: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/forgotPassword/verify-otp";
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  forgotPassword3: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/forgotPassword/reset";
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  changePassword: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/changePassword";
      instance()
        .patch(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  clientChangePassword: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "clients/init-mandate";
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default auth;
