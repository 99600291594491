import React, { useEffect } from "react";
import Functions, { useSetState } from "utils/functions.utils";
import "./ticket.screen.scss";
import "react-responsive-modal/styles.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Search from "common_components/ui/search/search.ui";
import Filter from "common_components/ui/filter/filter.ui";
import { Dropdown } from "primereact/dropdown";
import Button from "common_components/ui/button/button.ui";
import Models from "imports/models.import";

export const TICKET_STATUS = {
  PENDING: "pending",
  IN_PROCESS: "processing",
  CLOSED: "closed",
  CLIENT: "client",
};

const TICKET_PRIORITY = {
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
};
const TICKET_PRIORITY_COLOR = {
  [TICKET_PRIORITY.HIGH]: "#C01717",
  [TICKET_PRIORITY.MEDIUM]: "#1B365C",
  [TICKET_PRIORITY.LOW]: "#538234",
};

export default function Ticket() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathArr = pathname.split("/");

  const [state, setState] = useSetState({
    search: "",
    toggleFilter: false,
    filterStatus: "",
    loading: false,
    ticketPickedBy: "",
    users: [],
    pending_board_tickets: [
      {
        _id: 1234,
        priority: TICKET_PRIORITY.HIGH,
      },
      {
        _id: 1235,
        priority: TICKET_PRIORITY.LOW,
      },
    ],
    processing_board_tickets: [
      {
        _id: 1236,
        priority: TICKET_PRIORITY.LOW,
      },
    ],
    closed_board_tickets: [
      {
        _id: 1237,
        priority: TICKET_PRIORITY.MEDIUM,
      },
    ],
    client_board_tickets: [
      {
        _id: 1238,
        priority: TICKET_PRIORITY.HIGH,
      },
    ],
    selectedTicket: {},
    prevBoard: {},
  });
  const getUsersList = async () => {
    const body: any = {
      status: "APPROVED",
    };
    if (state.search.length > 0) {
      body.search = state.search;
      
    }
    try {
      setState({ loading: true });
      const res: any = await Models.user.getManyUser(body);
      const temp = res?.data?.map((item: any) => ({
        label: item?.fullName ?? "-- No name --",
        value: item?._id,
        email: item?.email,
      }));
      setState({ users: temp });
      console.log(res);
    } catch (err) {
      console.log(err);
    } finally {
      setState({ loading: false });
    }
  };
  const handleDragStart = (bItem: any, item: any) => {
    console.log("BITEM", bItem);
    let tempTickets = bItem.tickets?.filter((it) => it._id !== item._id);
    let key = bItem.key + "_board_tickets";
    setState({ prevBoard: { key, item: tempTickets }, selectedTicket: item });
  };
  const handleDrop = (event) => {
    console.log("DROP EVENT", event.target);
    const pending_board = document.getElementById("pending-board");
    const processing_board = document.getElementById("processing-board");
    const closed_board = document.getElementById("closed-board");
    const client_board = document.getElementById("client-board");
    if (
      pending_board?.contains(event.target) &&
      state.prevBoard.key.indexOf("pending") == -1
    ) {
      setState({
        [state.prevBoard.key]: state.prevBoard.item,
        pending_board_tickets: [
          state.selectedTicket,
          ...state.pending_board_tickets,
        ],
      });
    } else if (
      processing_board?.contains(event.target) &&
      state.prevBoard.key.indexOf("processing") == -1
    ) {
      setState({
        [state.prevBoard.key]: state.prevBoard.item,
        processing_board_tickets: [
          state.selectedTicket,
          ...state.processing_board_tickets,
        ],
      });
    } else if (
      closed_board?.contains(event.target) &&
      state.prevBoard.key.indexOf("closed") == -1
    ) {
      setState({
        [state.prevBoard.key]: state.prevBoard.item,
        closed_board_tickets: [
          state.selectedTicket,
          ...state.closed_board_tickets,
        ],
      });
    } else if (
      client_board?.contains(event.target) &&
      state.prevBoard.key.indexOf("client") == -1
    ) {
      setState({
        [state.prevBoard.key]: state.prevBoard.item,
        client_board_tickets: [
          state.selectedTicket,
          ...state.client_board_tickets,
        ],
      });
    }
    setState({ prevBoard: {}, selectedTicket: {} });
  };
  useEffect(() => {
    getUsersList();
  }, []);
  return (
    <div className="ticket_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="head h3">Tickets</div>
        </div>
        <div className="search_wrapper">
          <Search
            placeholder={"Search by Name, State, City, Pincode, Phone Number"}
            value={state.search}
            onChange={(search) => setState({ search ,currentPage: 1, totalItems: 0 })}
          />
          <div className="header-right">
            <div className="priority-legend ">
              <span className="high"></span>
              <span>High Priority</span>
            </div>
            <div className="priority-legend ">
              <span className="medium"></span>
              <span>Medium Priority</span>
            </div>
            <div className="priority-legend ">
              <span className="low"></span>
              <span>Low Priority</span>
            </div>
            <Filter
              onClick={() => setState({ toggleFilter: !state.toggleFilter })}
            />
            {/* @ts-ignore */}
            <Dropdown
              loading={state.loading}
              filter
              value={state.selectedUser}
              onChange={(e) => setState({ selectedUser: e.value })}
              options={state.users}
              optionLabel="label"
              placeholder={state.loading ? "Fetching Data..." : "Select ..."}
              // style={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
      <section className="board-container">
        {[
          {
            key: "pending",
            label: "Pending tickets",
            tickets: state.pending_board_tickets,
          },
          {
            key: "processing",
            label: "In process tickets",
            tickets: state.processing_board_tickets,
          },
          {
            key: "closed",
            label: "Closed tickets",
            tickets: state.closed_board_tickets,
          },
          {
            key: "client",
            label: "Assigned to client",
            tickets: state.client_board_tickets,
          },
        ].map((bItem, bIndex) => (
          <div key={bIndex} className="board">
            <div className="board-header">{bItem.label}</div>
            <div
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleDrop}
              id={bItem.key + "-" + "board"}
              className="board-body"
            >
              {bItem.tickets?.map((item, index) => (
                <div
                  draggable
                  onDragStart={(e) => handleDragStart(bItem, item)}
                  id={"ticket-" + item._id}
                  key={index}
                  className={`ticket-container `}
                >
                  <div
                    style={{
                      backgroundColor: TICKET_PRIORITY_COLOR[item?.priority],
                    }}
                    className={`priority-meter ${bItem.key}`}
                  ></div>
                  <div className="ticket-header">
                    Ticket ID #987654 ({item?._id})
                  </div>
                  <div className="ticket-content">Driver name : Soham Roy</div>
                  <div className="ticket-content">
                    City : Bangalore {"\t"} Client : Amazon
                  </div>
                  <div style={{ marginBottom: 10 }} className="ticket-content">
                    Issue : Vehicle not working
                  </div>

                  <div className="ticket-content">Comment -</div>
                  <div className="ticket-content muted">
                    {" "}
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Rerum esse aspernatur facilis at .
                  </div>
                  <div className="ticket-content muted">Image : Not added </div>

                  <div className="text-center py-3">
                    <div>
                      <Button
                        onClick={() => navigate("/ticket/view/" + item._id)}
                        value="View ticket"
                        style={{
                          padding: "5px 15px",
                          width: "100%",
                          borderRadius: 5,
                        }}
                      />
                      <div className="dropdown-primary">
                        {/* @ts-ignore */}
                        <Dropdown
                          style={{ width: "100%", marginTop: 10 }}
                          loading={state.loading}
                          filter
                          value={state.ticketPickedBy}
                          onChange={(e) =>
                            setState({ ticketPickedBy: e.value })
                          }
                          valueTemplate={(option) => (
                            <div>Picked by {option?.label}</div>
                          )}
                          options={state.users}
                          optionLabel="label"
                          placeholder={
                            state.loading
                              ? "Fetching Data..."
                              : "Please assign ticket"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}
