import React, { FC, useEffect, useRef, useState } from 'react'
import CustomModal from '../modal/modal.component';
//@ts-ignore
import GMapPicker from 'react-google-map-picker'
import "./google_maps_picker.ui.scss"
import { MapTypeId } from 'utils/interface.utils';
import Button from '../button/button.ui';
import GoogleAutocompletePlaces from '../google_autocomplete_place/google_autocomplete_place.ui';
//project specific molding the data
export type pointType = {
  coordinates : [lng:number,lat:number],
  type : "Point",
}
export type mapValueType = {
  fullAddress?: string,
  geoLocation : pointType
}
export interface MapPickerProps {
  label?: string;
  value: pointType;
  fullAddress :string,
  onChange: (location: mapValueType) => void
  style?: any;
  placeholder?: string
}





const MapPicker: FC<MapPickerProps> = ({
  label = "Label",
  onChange,
  style = { height: "500px", width: "500px" },
  fullAddress = "",
  placeholder = "Choose your location",
  value,
  ...rest
}) => {
  const [mapOpen, setMapOpen] = useState(false);
  const [location, setLocation] = useState<mapValueType>({
    fullAddress,
    geoLocation:value,
  });
  const [zoom, setZoom] = useState(16);


  async function getLocation() {
    setZoom(15)
    setLocation({
      fullAddress,
      geoLocation:value,
    })
  }

  function handleChangeLocation(lat: number, lng: number) {
    setLocation((prev)=>({...prev, geoLocation:{...prev.geoLocation,coordinates : [lng,lat]} }));
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }
  useEffect(() => {
    getLocation();
  }, [fullAddress])
  return (
    <React.Fragment>
      <div style={{ cursor: 'pointer' }} onClick={() => setMapOpen(true)} className="input_container">
        <div className="label_container caption2">{label}</div>
        <div className="field_wrap">
          <div className="map-pick-box">
            {
              !location.fullAddress ? 
                "Door no, Street name, Area name"
                :
                <span style={{color : 'black'}} >
                  {location.fullAddress}
                </span>
            }
          </div>
        </div>
      </div>
      <CustomModal open={mapOpen} onClose={() => setMapOpen(false)}>
        <GoogleAutocompletePlaces
          value={{
            lat : location.geoLocation?.coordinates[1],
            lng :location.geoLocation?.coordinates[0],
            formatted_address : location.fullAddress
          }}
          onChange={(loc) => {
            setLocation({
              fullAddress : loc.formatted_address,
              geoLocation : {
                type : "Point",
                coordinates : [loc.lng,loc.lat]
              }
            })
          }}

        />
        <div className="google_picker_container">
          <div style={{ width: style.width ?? 400, height: style.height ?? 400, margin: "20px 0", borderRadius: 20, overflow: 'hidden' }} className="map_picker_container">
            {
              location.fullAddress ?
                <GMapPicker
                  defaultLocation={{
                    lat : location.geoLocation.coordinates[1],
                    lng :location.geoLocation.coordinates[0],
                  }}
                  zoom={zoom}
                  mapTypeId={MapTypeId.Roadmap}
                  onChangeLocation={handleChangeLocation}
                  onChangeZoom={handleChangeZoom}
                  className="google_maps"
                  apiKey={"AIzaSyAaPjnlGUIBa9D65PqESe560a_DJ-gY0Oc"}
                />
                : null
            }
          </div>
          <div onClick={() => {
            setMapOpen(false);
            onChange(location)
          }} className="button_container">
            <Button value="Submit" />
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  )
}

export default MapPicker