import { error } from "console";
import instance from "utils/axios.utils";
import Functions from "utils/functions.utils";

const accessControl = {
    createAccessControl:(body)=>{
        let promise = new Promise((resolve,reject)=>{
           let url = "access-control"
           instance().post(url,body).then((res)=>{
            resolve(res.data);
           }).catch((error)=>{
            reject(Functions.modelError(error));
           });
        });
        return promise;
    },

    getAccessControlInSAMode:(query)=>{
       let promise = new Promise((resolve,reject)=>{
        let url = "access-control"
        instance().get(url,{ params: query }).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            reject(Functions.modelError(error));
        });
     })
     return promise;
    },

    getAccessControlIGeneralMode:()=>{
        let promise = new Promise((resolve,reject)=>{
            let url = "access-control"
            instance().get(url).then((res)=>{
                resolve(res.data);
            }).catch((error)=>{
              reject(Functions.modelError(error));
            })
        }) 
        return promise
    },

    deleteAccessControl:(payload)=>{
        let promise = new Promise((resolve,reject)=>{
            let url = 'access-control/sa/drop';
            instance().delete(url,{data:payload}).then((res)=>{
                resolve(res.data);
            }).catch((error)=>{
                reject(Functions.modelError(error))
            })
        })
        return promise
    }
}

export default accessControl;