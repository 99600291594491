import React, { useEffect } from "react";
import History from "components/history/history.component";
import Add from "components/add/add.component";
import Assets from "imports/assets.import";
import Button from "common_components/ui/button/button.ui";
import "./add_job.screen.scss";
import UploadButton from "common_components/ui/upload_button/upload_button.ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  removeEmptyValues,
  useSetState,
  toastifyError,
  jsonToFormData,
  simplifyJSON,
  createPayloadUpdate,
  toastify,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import { IAddValues } from "utils/interface.utils";
import { RC_DOC_UPLOAD_URL } from "utils/constant.utils";
import { toast } from "react-toastify";

export default function AddJob() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const pathArr = pathname.split("/");
  const isEditable = pathArr.includes("edit");

  const initialValue = {
    title : "",
    // state : "",
    location : "",
    joiningDate : "",
    qualification : "",
    jobType : "",
    tags : "",
    description : "",
  };
  const selectOption = [{ label: "Test", value: "test" }];

  const [state, setState] = useSetState({
    data: initialValue,
    loading: false,
    selectOptions: selectOption,
    buttonDisabled: false,
  });

  const inputFieldsWorking: IAddValues[] = [
    { label: "Job title", key: "title", type: "string" },
    // { label: "Job state", key: "state", type: "state" },
    { label: "location", key: "location", type: "string" },
    { label: "Joining date", key: "joiningDate", type: "future_date" },
    { label: "Qualification", key: "qualification", type: "string" },
    {
      label: "Job type",
      key: "jobType",
      options: [
        { label: "Part time", value: "PART_TIME" },
        { label: "Full time", value: "FULL_TIME" },
      ],
      type: "select",
    },
    {
      label: "Job tags",
      key: "tags",
      options: [
        { label: "Business Market", value: "business_market" },
        { label: "Sales", value: "sales" },
        { label: "Customer support", value: "customer_support" },
        { label: "Logistics", value: "logistics" },
        { label: "Data & Research", value: "data_and_research" },
        { label: "Editorial & Video", value: "editorial_and_video" },
        { label: "Accounts", value: "accounts" },
        { label: "Design", value: "design" },
        { label: "Legal & Compliance", value: "legal_and_compliance" },
        { label: "Product Management", value: "product_management" },
        { label: "Marketing", value: "marketing" },
        { label: "Security & IT Helpdesk", value: "security_and_it_helpdesk" },
        { label: "Content Writing", value: "content_writing" },
        { label: "Internships", value: "internships" },
      ],
      type: "select",
    },
    { label: "Job description", key: "description", type: "editor" },
  ];

  useEffect(() => {
    if (isEditable) {
      GetJob();
    }
  }, []);

  const GetJob = async () => {
    try {
      const response: any = await Models.careers.getJobById({
        _id: params.id,
      })
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const handleEdit = async (values) => {
    setState({ buttonDisabled: true });
    try {
      setState({loading : true})
      const final_data = {
        _id : params.id,
        title: values.title,
        description: values.description,
        tags: values.tags,
        location: values.location,
        qualification: values.qualification,
        jobType: values.jobType,
        joiningDate: values.joiningDate,
      }
      const res:any = await Models.careers.updateCareers(final_data);
      toastify(res?.message)
      navigate("/careers");
    } catch (err) {
      setState({ buttonDisabled: false });
      console.log(err);
      toastifyError(err);
    }finally{
      setState({loading : false})
    }
  };

  const handleCreate = async (values) => {
    
    console.log("values",values);
    try {
      setState({loading : true})
      const final_data = {
        title: values.title,
        description: values.description,
        tags: values.tags,
        location: values.location,
        qualification: values.qualification,
        jobType: values.jobType,
        joiningDate: values.joiningDate,
      }
      await Models.careers.createCareers(final_data);
      navigate("/careers");
    } catch (err) {
      // setState({ buttonDisabled: false });
      console.log(err);
      toastifyError(err);
    }finally{
      setState({loading : false})
    }
  };

  return (
    <div className="add_vehicle_container">
      <div className="add_vehicle_wrapper">
        <History
          name={state?.data?.vhModel}
          path={isEditable ? "Careers/edit" : "Careers/add"}
        />
        <div className="add_vehicle_body_container">
          <Add
            title={`${isEditable ? "Edit" : "Add"} Job`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputFieldsWorking}
            initialValues={initialValue}
            validationSchema="jobs"
            onSubmit={isEditable ? handleEdit : handleCreate}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            
          />
        </div>
      </div>
    </div>
  );

  function AddButton(props: any) {
    return (
      <div className="view_button_container">
        <div className="view_button_wrapper">
          {isEditable ? (
            <div className="view_button">
              <Button loading={state.loading} loadingText="Updating your details..."  buttonDisabled={props.buttonDisabled} value="Update" />
            </div>
          ) : (
            <div className="view_button">
              <Button loading={state.loading} loadingText="Creating job..." buttonDisabled={props.buttonDisabled} value="Save" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
