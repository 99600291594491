import { Form, Formik } from "formik";
import * as yup from "yup";
import "./login.screen.scss";
import InputField from "common_components/ui/field/field.ui";
import Button from "common_components/ui/button/button.ui";
import { useLocation, useNavigate } from "react-router-dom";
import { toastifyError } from "utils/functions.utils";
import Models from "imports/models.import";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state;
  console.log(email);

  const validationSchema = yup.object({
    otp: yup.string().required("Please enter otp"),
  });
  const handleLogin = async (values) => {
    try {
      const body = {
        email: email,
        otp: values.otp,
      };
      const login: any = await Models.auth.forgotPassword2(body);
      navigate("/sign-up/reset_password_confirm", { state: email });
    } catch (err) {
      toastifyError("There was a problem logging in!");
    }
  };
  return (
    <div className="login_screen">
      <div className="login_screen_wrapper">
        <div className="login_screen_container">
          <div className="login_input_wrapper">
            <div
              className="left_arrow"
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="bx bx-md bx-chevron-left text-white"></i>
            </div>
            <Formik
              onSubmit={handleLogin}
              validationSchema={validationSchema}
              initialValues={{
                email: "",
                password: "",
              }}
            >
              <Form>
                <div className="login_input_container">
                  <InputField
                    name="email"
                    type="text"
                    value={email}
                    disabled
                    placeholder={email}
                    optional={true}
                  />
                </div>
                <div className="login_input_container">
                  <InputField
                    name="otp"
                    type="password"
                    placeholder="Otp"
                    optional={true}
                  />
                </div>
                <div className="submit_button_container">
                  <Button width="100%" value="Confirm" />
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
