import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./view_warehouse.screen.scss";
import History from "components/history/history.component";
import View from "components/view/view.component";
import { Models } from "utils/imports.utils";
import { useSetState } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";

export default function ViewWarehouse() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = useSetState({
    data: {},
    deleteModal: false,
  });
  useEffect(() => {
    GetWarehouse();
  }, []);

  const GetWarehouse = async () => {
    try {
      const response: any = await Models.warehouse.getWarehouse({
        id: id,
      });
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
    }
  };

  const inputFields: IAddValues[] = [
    { label: "Name", key: "name", type: "string" },
    { label: "State", key: "address.state", type: "string", isNested: true },
    { label: "City", key: "address.city", type: "string", isNested: true },
    {
      label: "Full address",
      key: "address.fullAddress",
      type: "string",
      isNested: true,
    },
    {
      label: "Building name",
      key: "address.buildingName",
      type: "string",
      isNested: true,
    },
    {
      label: "Landmark",
      key: "address.landMark",
      type: "string",
      isNested: true,
    },
    {
      label: "Pincode",
      key: "address.pinCode",
      type: "string",
      isNested: true,
    },
    {
      label: "One Time Deposit Amount",
      key: "oneTimeDepositAmount",
      type: "string",
    },
    { label: "Monthly Rent", key: "monthlyRent", type: "string" },
    { label: "Agreement Start Date", key: "agreementStartDate", type: "date" },
    { label: "Agreement End Date", key: "agreementEndDate", type: "date" },
    {
      label: "Supervisors",
      key: "supervisors",
      secondaryKey: "name",
      type: "address",
      isNested: true,
    },
    {
      label: "Securities",
      key: "securityPersonDetails",
      secondaryKey: "name",
      type: "address",
      isNested: true,
    },
    {
      label: "Agreement Document",
      key: "agreementDocument",
      type: "passbook",
    },
  ];

  return (
    <div className="view_warehouse_container">
      <div className="view_warehouse_wrapper">
        <History name={state.data?.name} path={"view"} />
        <div className="view_warehouse_body_container">
          <View
            actions={[
              {
                key: "WAREHOUSE_EDIT",
                link: `/warehouse/edit/${id}`,
                icon: "edit",
              },
            ]}
            data={state.data}
            values={inputFields}
            head={<ViewHeader />}
            // buttons={<ViewButton />}
            hasFiles
          />
        </div>
      </div>
    </div>
  );
  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">{state?.data?.name}</div>
            <div className="view_head_sub_title h5">{`Warehouse ID - ${
              state?.data?.whId || ""
            }`}</div>
          </div>
        </div>
      </div>
    );
  }
}
