import { ADMIN_ROLES } from "common_components/hoc/admin.pov";
import { CLIENT_ROLES } from "common_components/hoc/client.pov";

export type sidebarRouteType = {
  boxIcon?: string;
  icon?: string;
  link: string;
  label: string;
  moduleName: string;
};
export const admin_routes: sidebarRouteType[] = [
  {
    boxIcon: "bxs-dashboard",
    link: "/dashboard",
    label: "Dashboard",
    moduleName: "DASHBOARD",
  },
  {
    icon: "hub",
    link: "/center",
    label: "Control center",
    moduleName: "CONTROL_CENTER",
  },
  {
    boxIcon: "bx-user",
    link: "/employee",
    label: "Employee",
    moduleName: "USER",
  },
  {
    boxIcon: "bxs-store-alt",
    link: "/client",
    label: "Client",
    moduleName: "CLIENT",
  },

  {
    icon: "driver",
    link: "/driver",
    label: "Driver",
    moduleName: "DRIVER",
  },
  {
    icon: "inventory",
    link: "/warehouse",
    label: "Warehouse",
    moduleName: "WAREHOUSE",
  },
  {
    icon: "vehicle",
    link: "/vehicle",
    label: "Vehicle Management",
    moduleName: "VEHICLE",
  },
  {
    boxIcon: "bx-log-in-circle",
    link: "/checkin",
    label: "Checkin",
    moduleName: "CHECKIN",
  },
  // {
  //   boxIcon: "bx-purchase-tag",
  //   link: "/ticket",
  //   label: "Ticket",
  //   moduleName : "TICKET"
  // },
  {
    boxIcon: "bx-briefcase",
    link: "/careers",
    label: "Careers",
    moduleName: "CAREER",
  },
  {
    icon: "access_control",
    link: "/access-control",
    label: "Access Control",
    moduleName: "ACCESS_CONTROL",
  },
  
  // _NAV_ ̰
];

export const client_routes: sidebarRouteType[] = [
  {
    boxIcon: "bxs-dashboard",
    link: "/client-pov/dashboard",
    label: "Client Dashboard",
    moduleName: "CLIENTPOV_DASHBOARD",
  },
  {
    boxIcon: "bxs-store-alt",
    link: "/client-pov/store",
    label: "Store",
    moduleName: "CLIENTPOV_STORE",
  },
];
const sidebarRoutes = (activeACL: string[] = []) => {
  const role = `${localStorage.getItem("role")}`;
  let routes: sidebarRouteType[] = [];
  const authorizedSidebarRoutes = (type: "ADMIN" | "CLIENT") => {
    let temp_routes: sidebarRouteType[] = [];
    if (type == "ADMIN") {
      if (role === "SA") {
        temp_routes = admin_routes;
      } else {
        for (var admRoute of admin_routes) {
          for (var acc of activeACL) {
            if (acc.indexOf(admRoute.moduleName) !== -1 || role === "SA") {
              temp_routes.push(admRoute);
              break;
            }
          }
        }
      }
    } else {
      if (role === "SA") {
        temp_routes = admin_routes;
      } else {
        for (var clientRoute of client_routes) {
          for (var acc of activeACL) {
            if (acc.indexOf(clientRoute.moduleName) || role === "SA") {
              temp_routes.push(clientRoute);
              break;
            }
          }
        }
      }
    }
    console.log("AUTHORIZED SIDE ROUTED", temp_routes);

    return temp_routes;
  };
  if (ADMIN_ROLES.includes(role)) {
    routes = [...routes, ...authorizedSidebarRoutes("ADMIN")];
  }
  if (CLIENT_ROLES.includes(role)) {
    routes = [...routes, ...client_routes];
  }
  return routes;
};

export default sidebarRoutes;
