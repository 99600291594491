import React, { useEffect } from "react";
import { useSetState } from "utils/functions.utils";
import { useSelector } from "react-redux";
import { reducers } from "interfaces/common.interface";
import Sidebar from "common_components/ui/sidebar/sidebar.ui";
import Assets from "imports/assets.import";
import "./main.scss";
import { useNavigate } from "react-router-dom";
import Models from "imports/models.import";

let socket: any;

export default function Main(props: any) {
  const navigate = useNavigate();
  const [state, setState] = useSetState({
    signout: false,
    loading: false,
    userImage: "",
  });
  const user = useSelector((store: reducers) => store.user);
  const email = localStorage.getItem("email");

  const role = localStorage.getItem("role");

  const showSuccess = (text: string) => {
    //show snack success
  };

  const throwError = (text: string) => {
    //show snack error
  };

  const setMainLoading = (loading: boolean) => {
    setState({ loading: loading });
  };

  const renderChildren = () => {
    return React.Children.map(props.children, (child: any) => {
      if (child) {
        return React.cloneElement(child, {
          user,
          showSuccess,
          throwError,
          socket,
          setMainLoading,
        });
      }
    });
  };
  useEffect(() => {
    GetMe();
  }, []);
  const GetMe = async () => {
    try {
      let res: any;
      res = await Models.customer.getMe();
      setState({ userImage: res.data.userImage });
    } catch (error) {
      console.log(error);
    }
  };
  // if (state.signout) window.location.href = "/";
  // if (state.loading) return <div>Loading</div>;
  // const token = localStorage.getItem("accessToken");
  // if (!token) window.location.href = "/sign-up";
  return (
    <div>
      <div className="main-wrapper">
        <div className="main-sidebar">
          <Sidebar />
        </div>
        <div className="main-body">
          <div className="personal_info">
            <div className="notification">
              <img src={Assets["bell"]} width={22} height={22} alt="view" />
            </div>
            <div className="settings">
              <img src={Assets["settings"]} width={22} height={22} alt="view" />
            </div>
            <div className="self" onClick={() => navigate("/profile")}>
              <div className="self_info">
                <div className="self_name">{email}</div>
                <div className="self_role">{role}</div>
              </div>
              <img
                className="self_logo"
                src={state.userImage || Assets["user"]}
              />
            </div>
          </div>
          {renderChildren()}
        </div>
      </div>
    </div>
  );
}
