import React from "react";
import filter_icon from "assets/icons/filter.svg";
import "./filter.ui.scss";

interface filterProps {
  onClick: any;
}
function Filter(props: filterProps) {
  const { onClick } = props;
  return (
    <div style={{cursor : 'pointer'}} onClick={onClick} className="filter_container">
      <div className="filter_icon_wrapper">
        <img src={filter_icon} className="filter_icon" alt="filter"  />
      </div>
      <div className="filter">Filter</div>
    </div>
  );
}

export default Filter;
