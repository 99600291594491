import React, { useEffect, useState } from "react";
import History from "components/history/history.component";
import Add from "components/add/add.component";
// import Assets from "imports/assets.import";
import Button from "common_components/ui/button/button.ui";
import "./add_center.screen.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  removeEmptyValues,
  useSetState,
  toastifyError,
  jsonToFormData,
  createPayloadUpdate,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import { IAddValues } from "utils/interface.utils";

export default function AddCenter() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const pathArr = pathname.split("/");
  const isEditable = pathArr.includes("edit");

  const initialValue = {
    city: "",
    state: "",
    controlCenterName: "",
    phone: "",
    fullAddress: "",
    pinCode: "",
    centerInCharge: "",
  };

  const [state, setState] = useSetState({
    data: initialValue,
    loading: false,
    buttonDisabled: false,
  });

  const [inputFields, setInputFields] = useState<IAddValues[]>([
    { label: "Name", key: "controlCenterName", type: "string" },
    { label: "Phone", key: "phone", type: "string" },
    { label: "State", key: "state", type: "state" },
    { label: "City", key: "city", type: "city", optional: true },
    { label: "Full address", key: "fullAddress", type: "string" },
    { label: "Pincode", key: "pinCode", type: "string" },

    {
      label: "Center incharge",
      key: isEditable ? "centerInCharge._id" : "centerInCharge",
      type: "user",
    },
    // {
    //   label: "Center members",
    //   key: "centerMembers",
    //   type: "multiselect",
    //   items : [
    //     {label : "one1" , value : "one"},
    //     {label : "two2" , value : "two"},
    //   ]
    // },
  ]);

  useEffect(() => {
    if (isEditable) {
      GetCenter();
    }
  }, []);
  const GetCenter = async () => {
    try {
      const response: any = await Models.center.getCenter({
        id: params.id,
      });
      // Data filtration
     
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  // const GetManyUser = async () => {
  //   const body: any = {
  //     adm: "ls",
  //   };
  //   // if (state.search.length > 0) {
  //   //   body.search = state.search;
  //   //
  //   // }
  //   try {
  //     const res: any = await Models.user.getManyUser(body);
  //     const temp = res?.data?.map((item, index) => ({
  //       label: item?.email,
  //       value: item?._id,
  //     }));
  //     console.log("temp", temp);
  //     setInputFields((prev) => [
  //       ...prev,
  //       {
  //         label: "Center members",
  //         key: "centerMembers",
  //         type: "multiselect",
  //         items: temp,
  //       },
  //     ]);
  //     //setState({ data: res?.data });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // useEffect(() => {
  //   GetManyUser();
  // }, []);
  const handleEdit = async (values) => {
    console.log(values);
    const data = createPayloadUpdate(removeEmptyValues(values), [
      "createdBy",
      "createdAt",
      "updatedAt",
      "modifiedBy",
      "centerId",
      "centerInCharge",
      "centerMembers",
    ]);
    data.centerInCharge = values.centerInCharge._id;
    setState({ buttonDisabled: true });
    try {
      let res = await Models.center.editCenter(data);
      navigate("/center");
    } catch (err) {
      setState({ buttonDisabled: false });
      console.log(err);
      toastifyError(err);
    }
  };

  const handleCreate = async (values) => {
    console.log(values);

    // setState({ buttonDisabled: true });
    try {
      const data: any = removeEmptyValues(values);
      // const payload = jsonToFormData(values);

      await Models.center.createCenter(data);
      navigate("/center");
    } catch (err) {
      // setState({ buttonDisabled: false });
      console.log(err);
      toastifyError(err);
    }
  };

  return (
    <div className="add_center_container">
      <div className="add_center_wrapper">
        <History
          name={state?.data?.controlCenterName}
          path={isEditable ? "Center/edit" : "Center/add"}
        />
        <div className="add_center_body_container">
          <Add
            title={`${isEditable ? "Edit" : "Add"} Center`}
            actions={[
              { link: "/", icon: "view" },
              { link: "/", icon: "store" },
            ]}
            data={state.data}
            values={inputFields}
            initialValues={initialValue}
            validationSchema={isEditable ? "editCenter" : "center"}
            onSubmit={isEditable ? handleEdit : handleCreate}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
      </div>
    </div>
  );

  function AddButton(props: any) {
    return (
      <div className="view_button_container">
        <div className="view_button_wrapper">
          {isEditable ? (
            <div className="view_button">
              <Button
                width="250px"
                buttonDisabled={props.buttonDisabled}
                value="Update"
              />
            </div>
          ) : (
            <div className="view_button">
              <Button
                width="250px"
                buttonDisabled={props.buttonDisabled}
                value="Save"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
