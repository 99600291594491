import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./view_store.screen.scss";
import History from "components/history/history.component";
import View from "components/view/view.component";
import Button from "common_components/ui/button/button.ui";
import Assets from "imports/assets.import";
import { Models } from "utils/imports.utils";
import { useSetState, toastifyError } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";
import CustomModal from "common_components/ui/modal/modal.component";
import DeletePopup from "components/delete_popup/delete_popup.component";
import RateCards from "components/rate_cards/rate_cards.component";
import { ADMIN_ROLES } from "common_components/hoc/admin.pov";

export default function ViewStore() {
  const navigate = useNavigate();
  const { clientId, id } = useParams();
  const [state, setState] = useSetState({
    data: {},
    organization: {},
    allocatedDrivers: []
  });
  useEffect(() => {
    GetStore();
    GetAllocatedDrivers()
  }, []);
  const GetAllocatedDrivers = async () => {
    try {
      const response: any = await Models.store.getAllocatedDriver(id as string);
      setState({ allocatedDrivers: response.data });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const GetStore = async () => {
    try {
      const response: any = await Models.store.getStore({
        id: id,
      });

      console.log(response.data);
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };



  const DeleteStore = async () => {
    try {
      await Models.store.deleteStore({
        store_id: state.data._id,
      });
      setState({ id: "", deleteModal: false });
      navigate(`/client/${clientId}/store`);
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const inputFields: IAddValues[] = [
    { label: "Store name", key: "storeName", type: "string" },
    { label: "Store ID", key: "storeId", type: "string" },
    {
      label: "Address",
      key: "fullAddress",
      type: "string",
      isNested: true,
    },
    { label: "Type", key: "type", type: "string" },
    { label: "State", key: "state", type: "string" },
    { label: "City", key: "city", type: "string" },
    {
      label: "Store incharge",
      key: "storeInCharge.name",
      type: "string",
      isNested: true,
    },
    {
      label: "Store incharge contact",
      key: "storeInCharge.number",
      type: "string",
      isNested: true,
    },
    {
      label: "Cluster lead",
      key: "ffCluster.fullName",
      type: "string",
      isNested: true,
    },
    {
      label: "Supervisor",
      key: "ffSupervisor.fullName",
      type: "string",
      isNested: true,
    },
    {
      label: "Control center",
      key: "controlCenterId.controlCenterName",
      type: "string",
      isNested: true,
    },
  ];

  const handleDelete = () => {
    setState({ deleteModal: true });
  };
  return (
    <div className="view_store_container">
      <div className="view_store_wrapper">
        <History
          name={state.data.name}
          path={`/client/${state?.data?.storeName}`}
        />
        <div className="view_store_body_container">
          <div className="view_container container-fluid p-5 ">
            <h3>Allocated Drivers ({state?.allocatedDrivers?.length ?? 0})</h3>
            <table width={"100%"} cellPadding={5} >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  state?.allocatedDrivers?.map((item, index) => (
                    <tr key={index} >
                      <td>{index + 1}</td>
                      <td>{item?.driverId?.fullName ?? "NA"}</td>
                      <td>{item?.driverId?.mobile ?? "NA"}</td>
                      <td className="text-center">
                        {
                          item?.driverId?._id ?
                            <Button
                              onClick={() => navigate(`/driver/view/${item?.driverId?._id}`)}
                              style={{ padding: "5px 10px" }} value="View driver" />
                            : "NA"
                        }
                      </td>
                    </tr>
                  ))
                }
                <tr></tr>
              </tbody>
            </table>
          </div>
          <br />
          <View
            actions={[
              {
                key: "STORE_EDIT",
                link: `/client/${clientId}/store/edit/${state.data._id}`,
                icon: "edit",
              },
            ]}
            data={state.data}
            values={inputFields}
            head={<ViewHeader />}
            hasFiles
          />

          <br />
          {state.data._id &&
            ADMIN_ROLES.includes(localStorage.getItem("role") + "") ? (
            <RateCards storeId={state.data._id} viewMode />
          ) : null}
        </div>
      </div>
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      >
        <DeletePopup
          onPress={DeleteStore}
          onCancel={() => setState({ deleteModal: false })}
        />
      </CustomModal>
    </div>
  );

  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">{state.data.storeName}</div>
            {/* <div className="view_head_sub_title h5">{`Enterprise - ${
              state?.data?.id || ''
            }`}</div> */}
          </div>
        </div>
      </div>
    );
  }
}
