import { Form, Formik } from "formik";
import * as yup from "yup";
import "./signup.screen.scss";
import InputField from "common_components/ui/field/field.ui";
import Button from "common_components/ui/button/button.ui";
import { useNavigate } from "react-router-dom";
import { toastifyError } from "utils/functions.utils";
import { Models } from "utils/imports.utils";

export default function ResetPassword() {
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Please enter a valid email !")
      .required("Please enter email"),
  });

  const navigate = useNavigate();

  const forgotPassword = async (values: any) => {
    try {
      const body = {
        email: values.email,
      };
      const res: any = await Models.auth.forgotPassword1(body);
      navigate("/sign-up/forgot_password", { state: values.email });
    } catch (err: any) {
      toastifyError(err);
    }
  };
  return (
    <div className="signup_screen">
      {/* <div
        className="left_arrow"
        onClick={() => {
          navigate(-1);
        }}
      >
        &#8592;
      </div> */}
      <div className="signup_screen_wrapper">
        <div className="signup_screen_container">
          <div className="signup_head_container">
            <div
              className="signup_head_wrapper"
              style={{ justifyContent: "center" }}
            >
              <div
                className="left_arrow"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="bx bx-md bx-chevron-left text-white"></i>
              </div>
              <div className="signup_head">Forgot Password</div>
              <div className="signup_desc">
                Check for security code in your registered account; if notfound,
                check spam.
              </div>
            </div>
          </div>
          <div className="signup_input_wrapper">
            <Formik
              onSubmit={forgotPassword}
              validationSchema={validationSchema}
              initialValues={{
                email: "",
              }}
            >
              <Form>
                <div className="signup_input_container">
                  <InputField
                    name="email"
                    type="text"
                    placeholder="Email"
                    optional={true}
                  />
                </div>
                <div className="submit_button_container">
                  <Button
                    width="100%"
                    color="#607EA8"
                    value="Send Code"
                  />
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
