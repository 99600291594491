import Colors from "imports/color.import";
import React from "react";
import "./button.ui.scss";
import Loader from "../loader/loader.ui";

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  value: string;
  onClick?: any;
  className?: string;
  loading?: boolean;
  color?: string;
  textColor?: string;
  borderColor?: string;
  buttonType?: string;
  buttonDisabled?: boolean;
  width?: string;
  loadingText?: string;
  boxIcon?: string;
}

const Button = (props: ButtonProps) => {
  const {
    value,
    onClick,
    className,
    color,
    textColor,
    borderColor,
    buttonType,
    width,
    loading = false,
    loadingText = "Loading Please wait...",
    boxIcon,
    ...rest
  } = props;

  return (
    <div  className="button_container" >
      <button
        {...rest}
        className={`${className} button`}
        // style={{
        //   color: textColor ? Colors[textColor] : Colors.white,
        //   borderColor: borderColor ? Colors[borderColor] : Colors.white,
        //   width: width,
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
        // @ts-ignore: Unreachable code error
        type={buttonType || "submit"}
        disabled={props.buttonDisabled}
        onClick={onClick}
      >
        {
          loading ?
            <>
              <Loader style={{ marginRight: 10 }} />
              {loadingText}
            </>
            :
            (
              <>
                {
                  boxIcon ?
                    <i style={value ? { paddingRight : 5 } : {}} className={`bx bx-sm ${boxIcon}`}></i> : null
                }
                {value}
              </>
            )

        }
      </button>
    </div>
  );
};

Button.defaultProps = {
  className: "",
  onClick: () => { },
  loading: false,
};

export default Button;
