import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
// import "./view_checkin.screen.scss";
import History from "components/history/history.component";
import View from "components/view/view.component";
import Button from "common_components/ui/button/button.ui";
import Assets from "imports/assets.import";
import { Models } from "utils/imports.utils";
import { useSetState, toastifyError } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";
import DeletePopup from "components/delete_popup/delete_popup.component";
import CustomModal from "common_components/ui/modal/modal.component";
import { useNavigate } from "react-router-dom";
import { VEHICLE_IMAGE_UPLOAD_URL } from "utils/constant.utils";

export default function ViewJob() {
  const { id } = useParams();
  const [state, setState] = useSetState({
    data: {},
    deleteModal: false,
  });
  useEffect(() => {
    GetJob();
  }, []);

  const GetJob = async () => {
    try {
      const response: any = await Models.careers.getJobById({
        _id: id,
      });
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
    }
  };

  const inputFields: IAddValues[] = [
    { label: "Job title", key: "title", type: "string" },
    // { label: "Job state", key: "state", type: "state" },
    { label: "location", key: "location", type: "string" },
    { label: "Joining date", key: "joiningDate", type: "future_date" },
    { label: "Qualification", key: "qualification", type: "string" },
    {
      label: "Job type",
      key: "jobType",
      type: "string",
    },
    {
      label: "Job tags",
      key: "tags",
      type: "string",
    },
    { label: "Job description", key: "description", type: "editor" },
  ];

  return (
    <div className="view_checkin_container">
      <div className="view_checkin_wrapper">
        <History name={`${state?.data?.title}`} path="view" />
        <div className="view_checkin_body_container">
          <View
            actions={[
              { key: "CAREER_EDIT", link: `/careers/edit/${id}`, icon: "edit" },
            ]}
            data={state.data}
            values={inputFields}
            // buttons={<ViewButton />}
            head={<ViewHeader />}
          />
        </div>
      </div>
    </div>
  );
  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">{state?.data?.title}</div>
            <div className="view_head_sub_title h5">{`Job ID - ${
              state?.data?.jobId || ""
            }`}</div>
          </div>
        </div>
      </div>
    );
  }
}
