import { useNavigate, useParams } from "react-router-dom";
import {
  useSetState,
  toastifyError,
  createExcelFile,
} from "utils/functions.utils";
import "./warehouse.screen.scss";
import Search from "common_components/ui/search/search.ui";
import Button from "common_components/ui/button/button.ui";
import Pagination from "components/pagination/pagination.component";
import { Models } from "utils/imports.utils";
import { useEffect } from "react";
import Assets from "imports/assets.import";
import _ from "lodash";
import Table from "components/table/table.component";
import pako from "pako";
import { Buffer } from "buffer";
import CustomModal from "common_components/ui/modal/modal.component";
import DeletePopup from "components/delete_popup/delete_popup.component";

export default function Charger() {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const { id } = useParams();

  const [state, setState] = useSetState({
    data: [],
    search: "",
    openModal: false,
    viewModal: false,
    deleteModal: false,
    loading: false,
    name: "",
  });

  const thead = [
    { head: "Charger Type", key: "chargerType", type: "string" },
    {
      head: "Charger Unit",
      key: "chargerUnit",
      type: "string",
    },
    { head: "ChargerValue", key: "chargerValue", type: "string" },
    {
      head: "Currently occupied",
      key: "currentlyOccupied",
      type: "allocation",
    },
    {
      head: "Capacity",
      key: "capacity",
      type: "string",
    },
    { head: "Connector", key: "connector", type: "string" },
    {
      head: "Vehicle type",
      key: "vehicleType",
      type: "string",
    },
    {
      head: "Availability",
      key: "availability",
      type: "string",
    },
  ];
  const GetManyData = async () => {
    try {
      let res: any;
      const body: any = {
        wId: id,
      };
      if (state.search.length > 0) {
        body.search = state.search;
      
      }
      res = await Models.warehouse.getManyCharger(body);
      setState({
        data: res?.data?.[0]?.chargingPoint,
        loading: false,
        name: res?.data?.[0]?.name,
      });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  useEffect(() => {
    GetManyData();
  }, [state.search]);

  const DeleteStore = async () => {
    try {
      await Models.store.deleteStore({
        ids: [state.id],
        target: "delete",
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: false,
      });
      setState({ id: "", deleteModal: false });
      GetManyData();
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const handleView = (data) => {
    navigate(`/warehouse/charger/view/${id}/${data?._id}`);
  };
  const handleDelete = (data) => {
    setState({ id: data._id, deleteModal: true });
  };

  return (
    <div className="store_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="header_details_wrapper">
            <div className="back_button_container">
              <img
                src={Assets.leftArrow}
                alt=""
                className="back_image"
                onClick={() => navigate(-1)}
              />
            </div>
            <div className="header_image_container">
              <img src={Assets.testPic} alt="" className="header_image" />
            </div>
            <div className="header_details">
              <div className="organization_name h5">{state?.name}</div>
              <div className="organization_contact_wrapper">
                <div className="organization_email">
                  {state?.organization?.phone}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search_wrapper">
          <Search
            value={state.search}
            onChange={(search) => setState({ search ,currentPage: 1, totalItems: 0 })}
            placeholder={""}
          />
          <div className="button_group">
            <Button
              value="Add Charger"
              onClick={() => {
                navigate(`/warehouse/charger/add/${id}`);
              }}
            />
          </div>
        </div>
      </div>
      <div className="storetable">
        <Table
          data={state.data}
          loading={state.loading}
          theads={thead}
          link="store"
          actions={[
            {
              key:"CHARGING_VIEW", //NOT PRESENT
              icon: "view",
              onClick: handleView,
            },
            {
              key:"CHARGING_DELETE", //NOT PRESENT
              icon: "delete",
              onClick: handleDelete,
            },
          ]}
        />
      </div>
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      >
        <DeletePopup
          onPress={DeleteStore}
          onCancel={() => setState({ deleteModal: false })}
        />
      </CustomModal>
    </div>
  );
}
