import InputField from "common_components/ui/field/field.ui";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Validation from "utils/validation.utils";
import React, { useRef, useState } from "react";
import "./add.component.scss";
import {
  removeEmptyValues,
  toastify,
  toastifyError,
  useSetState,
} from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";
import _ from "lodash";
import encrypt from "utils/encrypt";
import Models from "imports/models.import";

interface addProps {
  data: any;
  values: IAddValues[];
  onSubmit: any;
  id: any;
}

export default function AddVerification(props: addProps) {
  const { data, values, onSubmit } = props;
  const formikRef: any = useRef(null);

  const [state, setState] = useSetState({
    form: data,
  });
  const [verificationStatus, setVerificationStatus] = useState({});
  const handleChange = async (key, value) => {
    try {
      if (
        key === "dAadhaarCardNumber" &&
        Validation.aadharNumberRegex.test(value)
      ) {
        await handleVerification(key, value);
      }
      if (key === "dDlNumber" && Validation.licenseNumberRegex.test(value)) {
        await handleVerification(key, value);
      }
      if (key === "dPanNumber" && Validation.panNumberRegex.test(value)) {
        await handleVerification(key, value);
      }
    } catch (err) {
      toastifyError(err);
      setState({ buttonDisabled: false });
      console.log(err);
    }
  };

  const handleVerification = async (key, value) => {
    const jsonString = JSON.stringify({
      _id : props.id,
      key : value
    });
    const payload = await encrypt(jsonString);
    const body = {
      data: payload,
    };
    const response: any = await Models.driver.createPersonalInfo(body);
    console.log(response, props.id);

    if (response.data.verificationStatus) {
      setVerificationStatus((prevState) => ({
        ...prevState,
        [key]: response.data.verificationStatus,
      }));
    }
  };

  return (
    <div className="add_container">
      <div className="add_wrapper">
        <Formik
          ref={formikRef}
          onSubmit={onSubmit}
          initialValues={[]}
          enableReinitialize
        >
          <Form>
            <div className="add_field_body_container">
              <div className="add_field_body_wrapper">
                {values.map(({ label, key, type }) => (
                  <div key={key} className="add_field_container">
                    {type === "string" && (
                      <>
                        <InputField
                          name={key}
                          label={label}
                          type="text"
                          onChange={(e) => handleChange(key, e.target.value)}
                        />
                        {verificationStatus[key] && (
                          <span className="verified-checkbox">
                            &#10004; Verified
                          </span>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
