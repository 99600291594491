import Button from 'common_components/ui/button/button.ui'
import Divider from 'common_components/ui/divider/divider.ui';
import CircularBar from 'components/circular_progress_bar/circular_bar.component';
import Pagination from 'components/pagination/pagination.component';
import Models from 'imports/models.import';
import { Dialog } from 'primereact/dialog';
import React, { FC, useEffect } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useNavigate } from 'react-router-dom';
import { useSetState } from 'utils/functions.utils';
import StoreSummery from './store_summery.popup';
import SelectDropdown from 'common_components/ui/select_dropdown/select_dropdown.component';
import Colors from 'imports/color.import';
const ClientSummery: FC<{ cid: string, cuid: string }> = ({ cid, cuid }) => {
  const [state, setState] = useSetState({
    data: {},
    currentPage: 1,
    totalItems: 0,
    stores: [],
    selectedStore: "",
    storeSummeryVisible: false
  })
  const fetchStores = async () => {
    try {
      setState({ storeFetchLoading: true })

      const res: any = await Models.store.getManyStore({ client: cid })
      const temp = res?.data?.map((item, index) => ({
        label: item?.storeName,
        value: item?._id
      }))
      setState({ stores: temp })
    } catch (err) {
      console.log("Fetch Store Error : ", err)
    } finally {
      setState({ storeFetchLoading: false })
    }
  }
  const navigate = useNavigate();
  useEffect(() => {
    getData();
    fetchStores()
  }, [state.currentPage]);
  const getData = async () => {
    try {
      const response: any = await Models.checkin.getSummery({
        id: cuid,
        type: 'client',
        // page: state.currentPage,
        // pageSize: 5,
      });
      setState({ data: response.data, totalItems: response?.count, });
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div>
      <div className="container-fluid">
        <table width={"100%"}>
          <tbody>
            <tr>
              <th>Available Drivers </th>
              <td width={150} className='text-center'>{state.data?.availableDriverCount ?? 0} </td>
              <td colSpan={2} rowSpan={2} width={130}>
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <strong>Workforce Available</strong>
                    </div>
                    <div className="col-4"  >
                      <div className="d-flex" style={{ height: 100, width: 100, margin: "auto", alignSelf: 'center' }}>
                        <CircularProgressbar
                          value={state.data?.workforceAvailable ?? 0}
                          text={`${Math.floor(state.data?.workforceAvailable ?? 0)}%`}
                          styles={buildStyles({
                            textColor: "black",
                            pathColor: "#7F52FF",
                            // trailColor: "gold",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr >
              <th>Present Drivers </th>
              <td width={150} className='text-center'>{state.data?.presentDriverCount ?? 0} </td>
            </tr>
            <tr>
              <td width={300} >
                <div style={{marginRight  :50}}>
                  <SelectDropdown
                    notfound={"Not found"}
                    placeholder={"Store"}
                    data={state.stores}
                    onChange={(val: any) =>
                      setState({
                        selectedStore: val.value,

                      })
                    }
                    value={state.filterStore}
                  />
                </div>
              </td>
              <td width={150}>
                {
                  state.selectedStore ?
                    <Button
                      boxIcon="bxs-pie-chart-alt-2"
                      value="Store Summery"
                      onClick={() => setState({ storeSummeryVisible: true })}
                      style={{ cursor: "pointer", alignSelf: 'flex-start', backgroundColor: Colors.textdark }}
                    />
                    : null
                }
              </td>
            </tr>
          </tbody>
        </table>
        <Divider />
        {/* <div className='d-flex'>
          <SelectDropdown
            notfound={"Not found"}
            placeholder={"Store"}
            data={state.stores}
            onChange={(val: any) =>
              setState({
                selectedStore: val.value,

              })
            }
            value={state.filterStore}
          />
          {
            state.selectedStore ?
              <Button
                boxIcon="bxs-pie-chart-alt-2"
                value="Store Summery"
                onClick={() => setState({ storeSummeryVisible: true })}
                style={{ cursor: "pointer", alignSelf: 'flex-start', backgroundColor: Colors.textdark }}
              />
              : null
          }
        </div> */}
        <div className="row">
          <div className="col-md-6">
            <h6 className='text-center' >Available drivers</h6>
            <table className='table table-bordered' style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Driver name</th>
                  <th>Driver contact</th>
                  <th className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  state.data?.availableDriverDetails?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.driverId?.fullName ?? "NA"}</td>
                      <td>{item?.driverId?.mobile ?? "NA"}</td>
                      <td className="text-center">
                        {
                          item?.driverId?._id ?
                            <Button
                              onClick={() => navigate(`/driver/view/${item?.driverId?._id}`)}
                              style={{ padding: "5px 10px" }} value="View driver" />
                            : "NA"
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            {/* <div className="">
              <Pagination
                totalItems={state.totalItems}
                itemsPerPage={5}
                currentPage={state.currentPage}
                onPageChange={(page) => setState({ currentPage: page })}
              />
            </div> */}
          </div>
          <div className="col-md-6">
            <h6 className='text-center' >Present drivers</h6>
            <table className='table table-bordered' style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Driver name</th>
                  <th>Driver contact</th>
                  <th className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  state.data?.presentDriverDetails?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.createdBy?.fullName ?? "NA"}</td>
                      <td>{item?.createdBy?.mobile ?? "NA"}</td>
                      <td className="text-center">
                        {
                          item?.createdBy?._id ?
                            <Button
                              onClick={() => navigate(`/driver/view/${item?.createdBy?._id}`)}
                              style={{ padding: "5px 10px" }} value="View driver" />
                            : "NA"
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Dialog header="Store Summery" visible={state.storeSummeryVisible} style={{ width: '110vw' }} onHide={() => setState({ storeSummeryVisible: false })}>
        <StoreSummery sid={state.selectedStore} />
      </Dialog>
    </div>
  )
}

export default ClientSummery