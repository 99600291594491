import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ClientPOVStoreType } from 'store/slices/client.pov.store.slice'
import ClientPovStore from './client.pov.store'
import ClientPovLoggedInStore from './client.pov.logged_in.store'
import Loader from 'common_components/ui/loader/loader.ui'
import { getLoggedInClientPOVStore } from 'store/actions/client.pov.store.actions'

const ClientPovStoreAuthWrapper = () => {
  const clientPOVStore:ClientPOVStoreType = useSelector((s:any)=>s.clientPOVStore)
  const [loading , setLoading] = useState(true)
  const dispatch = useDispatch();
  const getStoreData = async()=>{
    setLoading(true)
    try{
      await dispatch(getLoggedInClientPOVStore())
    }catch(err){
      console.log("STORE GET ERR : ",err);
    }finally{
      setLoading(false)
    }
  }
  useEffect(()=>{
    getStoreData()
  },[])

  if(loading){
    return <div  style={{height : "80%" , width : "100%" ,display : 'flex' , justifyContent : 'center' , alignItems : 'center'}} >
      <Loader size={40}  primary />
    </div>
  }
  if(clientPOVStore.loggedIn){
    return <ClientPovLoggedInStore />
  }
  return (
    <ClientPovStore />
  )
}

export default ClientPovStoreAuthWrapper