import React from "react";
import "./pagination.component.scss";

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers: any = [];
    const maxVisiblePages = 5; // Maximum number of visible page numbers

    if (totalPages <= maxVisiblePages) {
      // If total pages is less than or equal to maxVisiblePages, display all page numbers
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={currentPage === i ? "active" : ""}
            onClick={() => onPageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      // Calculate the start and end page numbers
      let startPage = Math.max(
        currentPage - Math.floor(maxVisiblePages / 2),
        1
      );
      let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

      // Adjust startPage if endPage is at the end of totalPages
      if (endPage === totalPages) {
        startPage = Math.max(endPage - maxVisiblePages + 1, 1);
      }

      // Display page numbers with ellipses
      if (startPage > 1) {
        pageNumbers.push(
          <button key={1} onClick={() => onPageChange(1)}>
            1
          </button>,
          <span className="ellipsis-prev">...</span>
        );
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={currentPage === i ? "active" : ""}
            onClick={() => onPageChange(i)}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages) {
        pageNumbers.push(
          <span key="ellipsis-next">...</span>,
          <button key={totalPages} onClick={() => onPageChange(totalPages)}>
            {totalPages}
          </button>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className="pagination">
      <p className="page-info">
        Showing {totalItems !==0  ? ((currentPage - 1) * itemsPerPage + 1) : 0} -
        {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems} items
      </p>
      <div className="page-numbers">
        <button
          className="arrow-button prev"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        {renderPageNumbers()}
        <button
          className="arrow-button next"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Pagination;
