import React, { useEffect } from "react";
import {
  useSetState,
  toastifyError,
  createExcelFile,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import "./store.screen.scss";
import "react-responsive-modal/styles.css";
import DeletePopup from "components/delete_popup/delete_popup.component";
import Table from "components/table/table.component";
import Button from "common_components/ui/button/button.ui";
import Search from "common_components/ui/search/search.ui";
import Assets from "imports/assets.import";
import CustomModal from "common_components/ui/modal/modal.component";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import Pagination from "components/pagination/pagination.component";
import pako from "pako";
import { Buffer } from "buffer";
import AccessControlWrapper from "common_components/access_control.wrapper.hoc";

export default function Store() {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const role = localStorage.getItem("role");

  //state
  const [state, setState] = useSetState({
    data: [],
    search: "",
    openModal: false,
    viewModal: false,
    deleteModal: false,
    loading: false,
    organization: {},
    currentPage: 1,
    totalItems: 0,
  });

  const thead = [
    { head: "Date", key: "createdAt", type: "date-only" },
    { head: "Name", key: "storeName" },
    { head: "Store ID", key: "storeId" },
    { head: "Address", key: "fullAddress" },
    { head: "Type", key: "type" },
    { head: "Mobile", key: "phone" },
    { head: "Store incharge", key: "storeInCharge.name", isNested: true },
    { head: "Store incharge no.", key: "storeInCharge.number", isNested: true },
  ];
  // hooks
  useEffect(() => {
    GetManyData();
  }, [state.search,state.currentPage]);

  //network req
  const GetManyData = async () => {
    try {
      const body: any = {
        client: clientId,
        page: state.currentPage,
        pageSize: 10,
      };
      if (state.search.length > 0) {
        body.search = state.search;
      
      }
      setState({ loading: false });
      const res: any = await Models.store.getManyStore(body);
      setState({ data: res?.data, loading: false, totalItems: res?.count });
    } catch (error) {
      console.log(error);
    }
  };
  const DeleteStore = async () => {
    try {
      await Models.store.deleteStore({
        ids: [state.id],
        target: "delete",
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: false,
      });
      setState({ id: "", deleteModal: false });
      GetManyData();
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const handleView = (data) => {
    console.log(data);

    navigate(`/client/${clientId}/store/view/${data._id}`);
  };
  const handleEdit = (data) => {
    navigate(`/client/${clientId}/store/edit/${data._id}`);
  };
  const handleDelete = (data) => {
    setState({ id: data._id, deleteModal: true });
  };
  const handlePageChange = (page: number) => {
    setState({ currentPage: page });
  };
  const handleExport = async () => {
    const res: any = await Models.store.exportStore(clientId as string);
    const compressedString = res.data;
    const compressedBuffer = Buffer.from(compressedString, "base64");
    const base64String = pako.ungzip(compressedBuffer, { to: "base64" });
    createExcelFile(base64String);
  };
  return (
    <div className="store_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="header_details_wrapper">
            <div className="back_button_container">
              <img
                src={Assets.leftArrow}
                alt=""
                className="back_image"
                onClick={() => navigate(-1)}
              />
            </div>
            <div className="header_image_container">
              <img
                src={
                  state?.data?.[0]?.client?.userId?.userImage || Assets.testPic
                }
                alt=""
                className="header_image"
              />
            </div>
            <div className="header_details">
              <div className="organization_name h5">
                {state?.data?.[0]?.client?.userId?.fullName}
              </div>
              {/* <div className="organization_id">
                ID - {state?.organization?.id}
              </div> */}
              <div className="organization_contact_wrapper">
                <div className="organization_email">
                  {state?.organization?.phone}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search_wrapper">
          <Search
            value={state.search}
            onChange={(search) => setState({ search ,currentPage: 1, totalItems: 0 })}
            placeholder={"Name, Incharge No, Incharge Email, Mobile, StoreId"}
          />
          <div className="button_group">
            <AccessControlWrapper accessKey="STORE_EXPORT">
              <Button
                value="+ Export"
                onClick={() => {
                  handleExport();
                }}
              />
            </AccessControlWrapper>
            <AccessControlWrapper accessKey="STORE_ADD">
              <Button
                value="Add Store"
                onClick={() => {
                  navigate(`/client/${clientId}/store/add`);
                }}
              />
            </AccessControlWrapper>
          </div>
        </div>
      </div>
      <div className="storetable">
        <Table
          data={state.data}
          loading={state.loading}
          theads={thead}
          link="store"
          actions={[
            {
              key: "STORE_VIEW",
              icon: "view",
              onClick: handleView,
            },
            {
              key: "STORE_EDIT",
              icon: "edit",
              onClick: handleEdit,
            },
            {
              key: "STORE_DELETE",
              icon: "delete",
              onClick: handleDelete,
            },
          ]}
          imageKey="logo"
        />
      </div>
      <div className="driver_pagination">
        <Pagination
          totalItems={state.totalItems}
          itemsPerPage={10}
          currentPage={state.currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      >
        <DeletePopup
          onPress={DeleteStore}
          onCancel={() => setState({ deleteModal: false })}
        />
      </CustomModal>
    </div>
  );
}
