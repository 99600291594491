import React, { useEffect, useState } from "react";
import "./multiselect.component.scss";
import { useSetState } from "utils/functions.utils";
import OverlayClose from "../../common_components/ui/overlay_close/overlay_close.component";
import { Models } from "utils/imports.utils";
import Assets from "imports/assets.import";
import { MultiSelect } from "primereact/multiselect";
interface MultiUserSelectProps {
  label?: string;
  className?: string;
  name: string;
  placeholder: string;
  onChange: any;
  value?: string[];
  items: { label: any; value: any }[];
}

export default function MultiUserSelect(props: MultiUserSelectProps) {
  const { label, name, className, placeholder, onChange, value, items } = props;
  const [selectedCities, setSelectedCities] = useState(null);
  const [state, setState] = useSetState({
    open: false,
    isVisible: false,
    data: [],
    search: "",
    limit: 1000,
    value: Array.isArray(value) ? value : [],
  });

  useEffect(() => {
    GetManyUser();
  }, [state.search]);

  useEffect(() => {
    setState({
      data: state.data,
      value: Array.isArray(state.value) ? state.value : [],
    });
  }, [state.value]);

  const GetManyUser = async () => {
    const body: any = {
      status: "APPROVED",
    };
    if (state.search.length > 0) {
      body.search = state.search;
      
      
    }
    try {
      const res: any = await Models.user.getManyUser(body);
      setState({ data: res?.data });
    } catch (err) {
      console.log(err);
    }
  };

  const getValue = (data) => {
    return data._id;
  };

  const handleSelect = (selectedUsers) => {
    const selectedIds = Array.isArray(selectedUsers)
      ? selectedUsers.map(getValue)
      : [];

    const updatedValue = state.value.includes(selectedIds[0])
      ? state.value.filter((id) => id !== selectedIds[0])
      : [...state.value, ...selectedIds];

    setState({
      value: updatedValue,
      //   isVisible: false,
      open: false,
      search: "",
    });
    onChange(updatedValue);
  };
  const handleChange = (e) => {
    console.log(e.target.value);

    setState({ search: e.target.value });
    if (!e.target.value) {
      onChange("");
    }
  };

  const handleVisible = () => {
    setState({ isVisible: true, search: "" });
  };

  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];

  return (
    <div className="input_container">
      <div className="label_container caption2">{label}</div>
      {/* @ts-ignore */}
      <MultiSelect
        style={{ width: "100%" }}
        value={value}
        onChange={
          (e) => onChange(e.value)
          // setSelectedCities(e.value)
        }
        options={items}
        optionLabel="label"
        display="chip"
        placeholder="select one or multiple"
        maxSelectedLabels={3}
        className="w-full md:w-20rem"
      />
    </div>
  );
}
