const Colors = {
  // primary: "#6e69e4",
  primary: "#2ca8ff",
  // primary: "#659447",
  background: "#F0F4F9",
  border: "#e4e4e4",
  red: "#ff754c",
  redLight: "#fff6f3",
  white: "#ffffff",
  textdark:"#303972",
}

export default Colors