export interface IAddValues {
  key: string;
  label: string;
  type:
    | "string"
    | "file"
    | "file-all"
    | "files"
    | "select"
    | "multiselect"
    | "number"
    | "date"
    | "birthdate"
    | "future_date"
    | "dateRange"
    | "passbook"
    | "city"
    | "all_city"
    | "state"
    | "boolean"
    | "month"
    | "array"
    | "category"
    | "textarea"
    | "driver"
    | "vehicle"
    | "user"
    | "center"
    | "warehouse"
    | "address"
    | "map"
    | "editor"
    | "password"
    | "supervisor"
    | "security"
    | "capacity"
    | "space"
    | "power"
    | "year";
  secondaryKey?: string;
  isNested?: boolean;
  optional?: boolean;
  condition?: any;
  additionalInfo?: string;
  file_bucket_url?: string;
  options?: {
    value: number | string | boolean;
    label: string;
  }[];
  checkboxVisibilityKey?: null;
  items?: { label: any; value: any }[];
  hide?: boolean;
}

export enum MapTypeId {
  Roadmap = "roadmap",
  Satellite = "satellite",
  Hybrid = "hybrid",
  Terrain = "terrain",
}
