import React, { useEffect } from "react";
import History from "components/history/history.component";
import Add from "components/add/add.component";
import Assets from "imports/assets.import";
import Button from "common_components/ui/button/button.ui";
import "./add_vehicle.screen.scss";
import UploadButton from "common_components/ui/upload_button/upload_button.ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  removeEmptyValues,
  useSetState,
  toastifyError,
  jsonToFormData,
  simplifyJSON,
  createPayloadUpdate,
} from "utils/functions.utils";
import { Models, Validation } from "utils/imports.utils";
import { IAddValues } from "utils/interface.utils";
import {
  BIKE_IMAGE_UPLOAD_URL,
  INVOICE_UPLOAD_URL,
  RC_DOC_UPLOAD_URL,
} from "utils/constant.utils";
import DFormType from "d-forms-library/d_form/d-form.type";
import DForm from "d-forms-library/d_form";
import Loader from "common_components/ui/loader/loader.ui";

export default function AddVehicle() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const pathArr = pathname.split("/");
  const isEditable = pathArr.includes("edit");

  const initialValue = {
    vhType : "",
    vhNumber : "",
    vhRegName : "",
    vhRegContact : "",
    vhCompany : "",
    vhModel : "",
    leasingPartner : "",
    dealerName : "",
    purchaseDate : "",
    leasingTenure : "",
    leasingAmount : "",
    securityDeposit : "",
    residueAmount : "",
    professionalCharges : "",
    insuranceAmount : "",
    insuranceStartDate : "",
    insuranceEndDate : "",
    amcAmount : "",
    amcStartDate : "",
    amcEndDate : "",
    kmCapMonthly : "",
    aboveKmCapAmount : "",
    kmWarranty : "",
    vhTL : "",
    batteryOneSeqNumber : "",
    batteryOneWarrantyStartDate : "",
    batteryOneWarrantyEndDate : "",
    batteryTwoSeqNumber : "",
    batteryTwoWarrantyStartDate : "",
    batteryTwoWarrantyEndDate : "",
    motorNumber : "",
    chargerNumber : "",
    controllerNumber : "",
    converterNumber : "",
    chassisNumber : "",
    state : "",
    city : "",
    manufactureYear : "",
    vhImage : "",
    rcImageF : "",
    rcImageB : "",
    insuranceImage : "",
    invoiceImage : "",
    wareHouseId : "",
    insuranceNumber : "",
    // rcNumber : "",
  };
  const selectOption = [{ label: "Test", value: "test" }];

  const [state, setState] = useSetState({
    data: initialValue,
    pageLoading : true,
    loading: false,
    selectOptions: selectOption,
    buttonDisabled: false,
  });

  const inputFieldsWorking: IAddValues[] = [
    {
      label: "Carrier Type",
      key: "vhType",
      options: [
        { label: "2W", value: "2W" },
        { label: "3W", value: "3W" },
        { label: "4W", value: "4W" },
      ],
      type: "select"
    },
    { label: "Vehicle Registration Number", key: "vhNumber", type: "string" },
    { label: "Vehicle Reg Name", key: "vhRegName", type: "string" },
    { label: "Vehicle Reg Contact", key: "vhRegContact", type: "string", optional : true },
    { label: "Vehicle Make", key: "vhCompany", type: "string" },
    { label: "Vehicle Model", key: "vhModel", type: "string" },
    {
      label: "Leasing Partner",
      key: "leasingPartner",
      type: "string",
    },
    { label: "Dealer Name", key: "dealerName", type: "string" },
    { label: "Purchase Date", key: "purchaseDate", type: "birthdate" },
    { label: "Leasing Tenure (Months)", key: "leasingTenure", type: "string" },
    { label: "Monthly Leasing Amount", key: "leasingAmount", type: "string" },
    { label: "Security Deposit", key: "securityDeposit", type: "string", optional : true },
    { label: "Residue Amount", key: "residueAmount", type: "string" , optional : true},
    {
      label: "Professional Charges",
      key: "professionalCharges",
      type: "string",
      optional : true
    },
    { label: "Insurance Amount", key: "insuranceAmount", type: "string", optional : true },
    {
      label: "Insurance (Start Date)",
      key: "insuranceStartDate",
      type: "birthdate",
      optional : true
    },
    { label: "Insurance (End Date)", key: "insuranceEndDate", type: "future_date", optional : true },
    { label: "AMC Amount", key: "amcAmount", type: "string", optional : true },
    { label: "AMC (Start Date)", key: "amcStartDate", type: "birthdate", optional : true },
    { label: "AMC (End Date)", key: "amcEndDate", type: "future_date", optional : true },
    { label: "KM Cap Monthly", key: "kmCapMonthly", type: "string", optional : true },
    { label: "Above KM Cap in Rs", key: "aboveKmCapAmount", type: "string", optional : true },
    { label: "KM Warranty", key: "kmWarranty", type: "number" },
    { label: "Vehicle Tracking Link", key: "vhTL", type: "string" },
    {
      label: "Battery No - 1",
      key: "batteryOneSeqNumber",
      type: "string",
    },
    {
      label: "Battery No - 1 Warranty Start Date",
      key: "batteryOneWarrantyStartDate",
      type: "birthdate",
      optional : true
    },
    {
      label: "Battery No - 1 Warranty End Date",
      key: "batteryOneWarrantyEndDate",
      type: "future_date",
      optional : true
    },

    {
      label: "Battery No - 2",
      key: "batteryTwoSeqNumber",
      type: "string",
      optional: true,
    },
    {
      label: "Battery No - 2 Warranty Start Date",
      key: "batteryTwoWarrantyStartDate",
      type: "birthdate",
      optional: true,
    },
    {
      label: "Battery No - 2 Warranty End Date",
      key: "batteryTwoWarrantyEndDate",
      type: "future_date",
      optional: true,
    },
    {
      label: "Motor Number",
      key: "motorNumber",
      type: "string",
    },
    {
      label: "Charger Number (+)",
      key: "chargerNumber",
      type: "string",
    },
    {
      label: "Controller Number",
      key: "controllerNumber",
      type: "string",
      optional : true
    },
    { label: "Convertor Number", key: "converterNumber", type: "string", optional : true },
    { label: "Chassis Number", key: "chassisNumber", type: "string" },

    { label: "State", key: "state", type: "state" },
    { label: "City", key: "city", type: "city" },
    {
      label: "Manufacture Year",
      key: "manufactureYear",
      type: "year",
    },
    {
      label: "Bike Image (Document must be below 2 MB size) ",
      key: "vhImage",
      type: "file",
      file_bucket_url: BIKE_IMAGE_UPLOAD_URL,
      secondaryKey: "public",
    },
    {
      label: "RC Book Front (Document must be below 2 MB size) ",
      key: "rcImageF",
      type: "file",
      file_bucket_url: RC_DOC_UPLOAD_URL,
      secondaryKey: "private",
      optional : true
    },
    {
      label: "RC Book Back (Document must be below 2 MB size) ",
      key: "rcImageB",
      type: "file",
      file_bucket_url: RC_DOC_UPLOAD_URL,
      secondaryKey: "private",
      optional : true
    },
    { label: "Insurance Number", key: "insuranceNumber", type: "string" },
    {
      label: "Insurance Image (Document must be below 2 MB size) ",
      key: "insuranceImage",
      type: "file",
      file_bucket_url: RC_DOC_UPLOAD_URL,
      secondaryKey: "private",
      optional : true
    },
    {
      label: "Invoice Image (Document must be below 2 MB size) ",
      key: "invoiceImage",
      type: "file",
      file_bucket_url: INVOICE_UPLOAD_URL,
      secondaryKey: "private",
    },
    { label: "Warehouse", key: "wareHouseId", type: "warehouse" },
  ];

  useEffect(() => {
    if (isEditable) {
      GetVehicle();
    }else{
      setState({pageLoading : false})
    }
  }, []);

  const GetVehicle = async () => {
    try {
      const response: any = await Models.vehicle.getVehicle({
        id: params.id,
      });
      //data filtration
      let filterData = response.data.data[0];
      filterData = {
        ...filterData,
        wareHouseId : filterData?.wareHouseId?._id
      }
      setState({ data: filterData  });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }finally{
      setState({pageLoading : false})
    }
  };

  const handleEdit = async (values) => {
    setState({ buttonDisabled: true });
    try {
      const data = createPayloadUpdate(
        simplifyJSON(removeEmptyValues(values)),
        [
          "createdAt",
          "email",
          "updatedAt",
          "vId",
          "_id",
          "vehicle_id",
          "allocationStatus",
        ]
      );
      // const final_data ={
      //   _id : params.id,
      //   vhType : values.vhType,
      //   vhNumber : values.vhNumber,
      //   vhRegName : values.vhRegName,
      //   vhRegContact : values.vhRegContact,
      //   vhCompany : values.vhCompany,
      //   vhModel : values.vhModel,
      //   leasingPartner : values.leasingPartner,
      //   dealerName : values.dealerName,
      //   purchaseDate : values.purchaseDate,
      //   leasingTenure : values.leasingTenure,
      //   leasingAmount : values.leasingAmount,
      //   securityDeposit : values.securityDeposit,
      //   residueAmount : values.residueAmount,
      //   professionalCharges : values.professionalCharges,
      //   insuranceAmount : values.insuranceAmount,
      //   insuranceStartDate : values.insuranceStartDate,
      //   insuranceEndDate : values.insuranceEndDate,
      //   amcAmount : values.amcAmount,
      //   amcStartDate : values.amcStartDate,
      //   amcEndDate : values.amcEndDate,
      //   kmCapMonthly : values.kmCapMonthly,
      //   aboveKmCapAmount : values.aboveKmCapAmount,
      //   kmWarranty : values.kmWarranty,
      //   vhTL : values.vhTL,
      //   batteryOneSeqNumber : values.batteryOneSeqNumber,
      //   batteryOneWarrantyStartDate : values.batteryOneWarrantyStartDate,
      //   batteryOneWarrantyEndDate : values.batteryOneWarrantyEndDate,
      //   batteryTwoSeqNumber : values.batteryTwoSeqNumber,
      //   batteryTwoWarrantyStartDate : values.batteryTwoWarrantyStartDate,
      //   batteryTwoWarrantyEndDate : values.batteryTwoWarrantyEndDate,
      //   motorNumber : values.motorNumber,
      //   chargerNumber : values.chargerNumber,
      //   controllerNumber : values.controllerNumber,
      //   converterNumber : values.converterNumber,
      //   chassisNumber : values.chassisNumber,
      //   state : values.state,
      //   city : values.city,
      //   manufactureYear : values.manufactureYear,
      //   vhImage : values.vhImage,
      //   rcImageF : values.rcImageF,
      //   rcImageB : values.rcImageB,
      //   insuranceImage : values.insuranceImage,
      //   invoiceImage : values.invoiceImage,
      //   wareHouseId : typeof values.wareHouseId === 'string' ? values.wareHouseId : values.wareHouseId?._id,
      //   insuranceNumber : values.insuranceNumber,
      //   // rcNumber : values.rcNumber,
      // }
      const formdata = jsonToFormData({_id : params.id,...values});
      console.log("vehicle edit values", formdata);

      await Models.vehicle.editVehicle(formdata);
      navigate("/vehicle");
    } catch (err) {
      setState({ buttonDisabled: false });
      console.log(err);
      toastifyError(err);
    }
  };

  const handleCreate = async (values) => {
    // setState({ buttonDisabled: true });
    console.log(values);

    try {
      values.vhTrackingSystemProvider = "INTELLICAR";
      const data: any = jsonToFormData(values);
      await Models.vehicle.createVehicle(data);
      navigate("/vehicle");
    } catch (err) {
      // setState({ buttonDisabled: false });
      console.log(err);
      toastifyError(err);
    }
  };
  const addVehicleForm:DFormType[]=[
    {

      sectionLabel : isEditable ?  "Update vehicle details":"Please fill the details to create a vehicle",
      defaultGrid : 'col-md-6',
      validationSchema : Validation.vms,
      initialValues : initialValue,
      fields : [
        {
          label: "Carrier Type",
          key: "vhType",
          options: [
            { label: "2W", value: "2W" },
            { label: "3W", value: "3W" },
            { label: "4W", value: "4W" },
          ],
          type: "select"
        },
        { label: "Vehicle Registration Number", key: "vhNumber", type: "string" },
        { label: "Vehicle Reg Name", key: "vhRegName", type: "string" },
        { label: "Vehicle Reg Contact", key: "vhRegContact", type: "string", optional : true },
        { label: "Vehicle Make", key: "vhCompany", type: "string" },
        { label: "Vehicle Model", key: "vhModel", type: "string" },
        {
          label: "Leasing Partner",
          key: "leasingPartner",
          type: "string",
        },
        { label: "Dealer Name", key: "dealerName", type: "string" },
        { label: "Purchase Date", key: "purchaseDate", type: "previous-date" },
        { label: "Leasing Tenure (Months)", key: "leasingTenure", type: "string" },
        { label: "Monthly Leasing Amount", key: "leasingAmount", type: "string" },
        { label: "Security Deposit", key: "securityDeposit", type: "string", optional : true },
        { label: "Residue Amount", key: "residueAmount", type: "string" , optional : true},
        {
          label: "Professional Charges",
          key: "professionalCharges",
          type: "string",
          optional : true
        },
        { label: "Insurance Amount", key: "insuranceAmount", type: "string", optional : true },
        {
          label: "Insurance (Start Date)",
          key: "insuranceStartDate",
          type: "previous-date",
          optional : true
        },
        { label: "Insurance (End Date)", key: "insuranceEndDate", type: "future-date", optional : true },
        { label: "AMC Amount", key: "amcAmount", type: "string", optional : true },
        { label: "AMC (Start Date)", key: "amcStartDate", type: "previous-date", optional : true },
        { label: "AMC (End Date)", key: "amcEndDate", type: "future-date", optional : true },
        { label: "KM Cap Monthly", key: "kmCapMonthly", type: "string", optional : true },
        { label: "Above KM Cap in Rs", key: "aboveKmCapAmount", type: "string", optional : true },
        { label: "KM Warranty", key: "kmWarranty", type: "number" },
        { label: "Vehicle Tracking Link", key: "vhTL", type: "string" },
        {
          label: "Battery No - 1",
          key: "batteryOneSeqNumber",
          type: "string",
        },
        {
          label: "Battery No - 1 Warranty Start Date",
          key: "batteryOneWarrantyStartDate",
          type: "previous-date",
          optional : true
        },
        {
          label: "Battery No - 1 Warranty End Date",
          key: "batteryOneWarrantyEndDate",
          type: "future-date",
          optional : true
        },
    
        {
          label: "Battery No - 2",
          key: "batteryTwoSeqNumber",
          type: "string",
          optional: true,
        },
        {
          label: "Battery No - 2 Warranty Start Date",
          key: "batteryTwoWarrantyStartDate",
          type: "previous-date",
          optional: true,
        },
        {
          label: "Battery No - 2 Warranty End Date",
          key: "batteryTwoWarrantyEndDate",
          type: "future-date",
          optional: true,
        },
        {
          label: "Motor Number",
          key: "motorNumber",
          type: "string",
        },
        {
          label: "Charger Number (+)",
          key: "chargerNumber",
          type: "string",
        },
        {
          label: "Controller Number",
          key: "controllerNumber",
          type: "string",
          optional : true
        },
        { label: "Convertor Number", key: "converterNumber", type: "string", optional : true },
        { label: "Chassis Number", key: "chassisNumber", type: "string" },
    
        { label: "State", key: "state", type: "state" },
        { label: "City", key: "city", type: "city" , linkedWith : 'state'},
        {
          label: "Manufacture Year",
          key: "manufactureYear",
          type: "select",
          options: [
            { label: "2024", value: "2024" },
            { label: "2023", value: "2023" },
            { label: "2022", value: "2022" },
            { label: "2021", value: "2021" },
            { label: "2020", value: "2020" },
            { label: "2019", value: "2019" },
            { label: "2019", value: "2019" },
            { label: "2018", value: "2018" },
            { label: "2017", value: "2017" },
            { label: "2016", value: "2016" },
            { label: "2015", value: "2015" },
            { label: "2014", value: "2014" },
            { label: "2013", value: "2013" },
            { label: "2012", value: "2012" },
            { label: "2011", value: "2011" },
            { label: "2010", value: "2010" },
          ],
        },
        {
          label: "Bike Image (Document must be below 2 MB size) ",
          key: "vhImage",
          type: "image-picker",
          extraProps : {accessType: "public", file_bucket_url: BIKE_IMAGE_UPLOAD_URL,},
        },
        {
          label: "RC Book Front (Document must be below 2 MB size) ",
          key: "rcImageF",
          type: "image-picker",
          extraProps : {accessType: "private", file_bucket_url: RC_DOC_UPLOAD_URL,},
          optional : true
        },
        {
          label: "RC Book Back (Document must be below 2 MB size) ",
          key: "rcImageB",
          type: "image-picker",
          extraProps : {accessType: "private", file_bucket_url: RC_DOC_UPLOAD_URL,},
          optional : true
        },
        { label: "Insurance Number", key: "insuranceNumber", type: "string" },
        {
          label: "Insurance Image (Document must be below 2 MB size) ",
          key: "insuranceImage",
          type: "image-picker",
          extraProps : {accessType: "private", file_bucket_url: RC_DOC_UPLOAD_URL,},
          optional : true
        },
        {
          label: "Invoice Image (Document must be below 2 MB size) ",
          key: "invoiceImage",
          type: "image-picker",
          extraProps : {accessType: "private", file_bucket_url: INVOICE_UPLOAD_URL,},
        },
        { label: "Warehouse", key: "wareHouseId", type: "warehouse" },
      ],
      onSubmit : isEditable ? handleEdit : handleCreate
    }
  ]
  return (
    <div className="add_vehicle_container">
      <div className="add_vehicle_wrapper">
        <History
          name={state?.data?.vhModel}
          path={isEditable ? "Vehicle/edit" : "Vehicle/add"}
        />
        <div className="add_vehicle_body_container">
          {
            !state.pageLoading ? 
            <DForm 
              data={addVehicleForm}
              fillSections={[isEditable ? state.data : {}]}
            />
            : 
            <div style={{height : 400 , display : 'flex', justifyContent : 'center' , alignItems : 'center'}} >
              <div>
                <Loader size={50} primary />
              </div>
              <h3 className="px-4 m-0">Loading Please wait...</h3>
            </div>
          }
          </div>
          {/* <Add
            title={`${isEditable ? "Edit" : "Add"} Vehicle`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputFieldsWorking}
            initialValues={initialValue}
            validationSchema="vms"
            onSubmit={isEditable ? handleEdit : handleCreate}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          /> */}
      </div>
    </div>
  );

  function AddButton(props: any) {
    return (
      <div className="view_button_container">
        <div className="view_button_wrapper">
          {isEditable ? (
            <div className="view_button">
              <Button buttonDisabled={props.buttonDisabled} value="Update" />
            </div>
          ) : (
            <div className="view_button">
              <Button buttonDisabled={props.buttonDisabled} value="Save" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
