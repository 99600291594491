import instance from "utils/axios.utils";
import Functions from "utils/functions.utils";
import { setManyDriver, setDriver } from "utils/redux.utils";

const driver = {
  createDriverBasic: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "drivers/register/basic";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  createDriverUpload: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "drivers/register/upload";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  createDriverBankDetails: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "drivers/register/details";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  createDriverEmergency: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "drivers";
      instance()
        .put(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  createPersonalInfo: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "drivers/doc-ids";
      instance()
        .put(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  getManyDriver: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "drivers";
        const response = await instance().get(url, { params: query });
        setManyDriver(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getDriver: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "drivers";
        const response = await instance().get(url, { params: id });
        setDriver(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  approveDriver: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "drivers/approved";
      instance()
        .patch(url, body)
        .then((res) => {
          if (res.data.status) {
            resolve(res.data);
          } else {
            reject(res.data);
          }
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  rejectDriver: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "drivers/rejected";
      instance()
        .patch(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  resignDriver: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "drivers/resigned";
      instance()
        .patch(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  terminateDriver: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "drivers/terminated";
      instance()
        .patch(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  editDriverBasic: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "drivers/register/basic";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  editDriverUpload: (body: FormData) => {
    console.log("entereerrrr 1", body.values);
    body.delete("driverDeposit");
    body.delete("driverDepositRemarks");
    console.log("entereerrrr 2", body.values);
    let promise = new Promise((resolve, reject) => {
      let url = "drivers/register/upload";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  editDriverBankDetails: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "drivers/register/details";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  editDriverEmergency: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "drivers";
      instance()
        .put(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  deleteDriver: (payload) => {
    let promise = new Promise((resolve, reject) => {
      let url = "drivers/sa/drop";
      instance()
        .delete(url, { data: payload })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  exportDriver: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "drivers?export=1";
        const response = await instance().get(url);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  allotVehicleToDriver: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "vehicle/allocate";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  allotClientToDriver: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "clients/allocate-driver";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  getAllocatedClient: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "clients/available-drivers";
        const response = await instance().get(url, { params: query });
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  deAllocation: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `vehicle/de-allocate/${id}`; // Attach id to the URL
        const response = await instance().patch(url);
        setDriver(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  generateDriverId: (mobile) => {
    return new Promise(async (resolve, reject) => {
      let response: any = [];
      try {
        const url = `drivers/reMap?mobile=${mobile}`;
        response = await instance().put(url);
        setDriver(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
};
export default driver;
