import * as yup from "yup";
const phoneRegExp = /^[0-9]{10}$/;
export const aadharNumberRegex = /^\d{12}$/;
export const licenseNumberRegex = /^[A-Za-z0-9]{6,}$/;
export const panNumberRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
export const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+{}|:<>?~])\S{8,}$/;
const gstRegex = /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[1-9A-Z]{1})$/;
const pinRegex = /^[1-9][0-9]{5}$/;
export const emailRegExp = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
const rcRegex = /^[a-zA-Z0-9]*$/;
const incRegex = /^[A-Z]{3}[0-9]{9}$/;
export const test = yup.object({});

export const user = yup.object({
  fullName: yup.string().required("enter full name"),
  role: yup.mixed().optional(),
  gender: yup.string().optional(),
  dob: yup.mixed().optional(),
  bloodGroup: yup.string().optional(),
  mobile: yup
    .number()
    .integer("Please enter an valid mobile number")
    .typeError("Please enter an valid mobile number")
    .moreThan(999999999, "mobile must be 10 digits")
    .optional(),
  wapNumber: yup
    .number()
    .integer("Please enter an valid mobile number")
    .typeError("Please enter an valid mobile number")
    .moreThan(999999999, "mobile must be 10 digits")
    .optional(),
  secondaryMobileNo: yup
    .number()
    .integer("Please enter an valid mobile number")
    .typeError("Please enter an valid mobile number")
    .moreThan(999999999, "mobile must be 10 digits")
    .optional(),
  fatherName: yup.string().optional(),
  languages: yup.mixed().optional(),
  email: yup
    .string()
    .required("Please enter email")
    .matches(emailRegExp, "Please enter valid email"),
  aadhaarDocument: yup.string().optional(),
  panDocument: yup.string().optional(),
  userImage: yup.string().optional(),
  controlCenterId: yup.string().optional(),
});
export const driverBasic = yup.object({
  mobile: yup
    .string()
    .min(10)
    .max(10)
    .required("Please enter mobile phone")
    .matches(phoneRegExp, "Please enter valid mobile number"),
  wapNumber: yup
    .string()
    .min(10)
    .max(10)
    .optional()
    .matches(phoneRegExp, "Please enter valid mobile number"),
  gender: yup.string().required("Please select gender"),
  bloodGroup: yup.string().required("Please select blood group"),
  fullName: yup
    .string()
    .required("Please enter name")
    .matches(/^[a-zA-Z\s-]+$/, "Please enter alphabet letters only"),
  state: yup.string().required("Please select a state"),
  city: yup.string().required("Please select a city"),
  dob: yup.string().required("Please choose your birthDate"),
  driverRegistrationType: yup.string().required("Please select a status"),
  fullAddress: yup.string().required("Please enter full address"),
  fatherName: yup
    .string()
    .required("Please enter father's name")
    .matches(/^[a-zA-Z\s-]+$/, "Please enter alphabet letters only"),
  pinCode: yup
    .string()
    .required("Please enter pincode")
    .matches(pinRegex, "Please enter valid pin"),
  face: yup.string().required("Please upload profile pic"),
});
export const organization = yup.object({
  fullName: yup
    .string()
    .required("Please enter name")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  category: yup.string().required("Please select a category"),
  type: yup.string().required("Please select a type"),
  email: yup
    .string()
    .required("Please enter email")
    .matches(emailRegExp, "Please enter valid email"),
  mobile: yup
    .string()
    .min(10)
    .max(10)
    .required("Please enter mobile phone")
    .matches(phoneRegExp, "Please enter valid mobile number"),
  state: yup.string().required("Please select a state"),
  buildingName: yup.string().required("Please enter building name"),
  landMark: yup.string().required("Please enter landmark"),
  fullAddress: yup.string().required("Please enter full address"),
  pinCode: yup
    .string()
    .required("Please enter pincode")
    .matches(pinRegex, "Please enter valid pin"),
  gstNo: yup.string().optional(),
  // .matches(gstRegex, "Please enter valid GST Number"),
  panNumber: yup
    .string()
    .matches(panNumberRegex, "Invalid PAN card number")
    .optional(),
  contractPeriod: yup.string().required("Please enter contract period"),
  contractStartDate: yup.date().required("Please enter contract start date"),
  contractEndDate: yup.date().required("Please enter contract end date"),
});
export const vehicle = yup.object({
  city: yup.string().required("Please select a city"),
  state: yup.string().required("Please select a state"),
  vhModel: yup.string().required("Please enter model"),
  vhNumber: yup.string().required("Please enter number"),
  vhCompany: yup.string().required("Please enter company"),
  ownerName: yup.string().required("Please enter owner name"),
  vhType: yup.string().required("Please select type"),
  isOwnVehicle: yup.string().required("Please select yes/no"),
  rcNumber: yup
    .string()
    .required("Please enter rc number")
    .matches(rcRegex, "Please enter valid rc number"),
  insuranceNumber: yup.string().required("Please enter insurance number"),
  // .matches(incRegex, "Please enter valid insurance number"),
  vhImage: yup.string().required("Please upload vehicle image"),
  rcImageF: yup.string().required("Please upload rc book front"),
  rcImageB: yup.string().required("Please upload rc book back"),
  insuranceImage: yup.string().required("Please upload insurance doc"),
});
export const center = yup.object({
  controlCenterName: yup.string().required("Please enter center name"),
  phone: yup
    .string()
    .min(10)
    .max(10)
    .required("Please enter mobile phone")
    .matches(phoneRegExp, "Please enter valid mobile number"),
  state: yup.string().required("Please select a state"),
  fullAddress: yup.string().required("Please enter full address"),
  pinCode: yup
    .string()
    .required("Please enter pincode")
    .matches(pinRegex, "Please enter valid pin"),
  centerInCharge: yup.string().required("Please select a center incharge"),
});
export const store = yup.object({
  storeName: yup.string().required("Please enter center name"),
  phone: yup
    .string()
    .min(10)
    .max(10)
    .required("Please enter mobile phone")
    .matches(phoneRegExp, "Please enter valid mobile number"),
  state: yup.string().required("Please select a state"),
  fullAddress: yup.mixed().required("Please enter full address"),
  type: yup.string().required("Please select type"),
  controlCenterId: yup.string().required("Please select control center"),
  storeInCharge: yup.object().shape({
    name: yup.string().required("Name is required"),
    number: yup
      .string()
      .matches(/^[0-9]{10}$/, "Invalid phone number")
      .required("Phone number is required"),
    emailId: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  }),
});
export const jobs = yup.object({
  title: yup.string().required("Please enter job title"),
  // state: yup.string().required("Please choose state name"),
  location: yup.string().required("Please choose job location"),
  joiningDate: yup.string().required("Please enter joining date"),
  qualification: yup.string().required("Please enter job qualification"),
  jobType: yup.string().required("Please choose job type"),
  tags: yup.string().required("Please choose a job tag"),
  description: yup.string().required("Please enter job description").nullable(),
});
export const driver_vehicle_allocation = yup.object({
  vehicleId: yup.string().required("Please choose a vehicle first."),
});
export const powerBill = yup.object({
  monthFor: yup.string().required("Please enter month"),
  billNumber: yup.string().required("Please enter bill number").nullable(),
  initialReadings: yup.string().required("Please enter initial readings").nullable(),
  billDocument: yup.mixed().required("Please upload bill document").nullable(),
  paidAmount: yup.string().required("Please enter paid amount").nullable(),
});
export const vms = yup.object({
  vhType : yup.string().required("Please enter Vh Type").nullable(),
  vhNumber : yup.string().required("Please enter Vh Number").nullable(),
  vhRegName : yup.string().required("Please enter Vh Reg Name").nullable(),
  vhRegContact : yup.string().optional().nullable(),
  vhCompany : yup.string().required("Please enter Vh Company").nullable(),
  vhModel : yup.string().required("Please enter Vh Model").nullable(),
  leasingPartner : yup.string().required("Please enter Leasing Partner").nullable(),
  dealerName : yup.string().required("Please enter Dealer Name").nullable(),
  purchaseDate : yup.string().required("Please enter Purchase Date").nullable(),
  leasingTenure : yup.string().required("Please enter Leasing Tenure").nullable(),
  leasingAmount : yup.string().required("Please enter Leasing Amount").nullable(),
  securityDeposit : yup.string().optional().nullable(),
  residueAmount : yup.string().optional().nullable(),
  professionalCharges : yup.string().optional().nullable(),
  insuranceAmount : yup.string().optional().nullable(),
  insuranceStartDate : yup.string().optional().nullable(),
  insuranceEndDate : yup.string().optional().nullable(),
  amcAmount : yup.string().optional().nullable(),
  amcStartDate : yup.string().optional().nullable(),
  amcEndDate : yup.string().optional().nullable(),
  kmCapMonthly : yup.string().optional().nullable(),
  aboveKmCapAmount : yup.string().optional().nullable(),
  kmWarranty : yup.string().required("Please enter Km Warranty").nullable(),
  vhTL : yup.string().required("Please enter Vh Tl").nullable(),
  batteryOneSeqNumber : yup.string().required("Please enter Battery One Seq Number").nullable(),
  batteryOneWarrantyStartDate : yup.string().optional().nullable(),
  batteryOneWarrantyEndDate : yup.string().optional().nullable(),
  batteryTwoSeqNumber : yup.string().optional().nullable(),
  batteryTwoWarrantyStartDate : yup.string().optional().nullable(),
  batteryTwoWarrantyEndDate : yup.string().optional().nullable(),
  motorNumber : yup.string().required("Please enter Motor Number").nullable(),
  chargerNumber : yup.string().required("Please enter Charger Number").nullable(),
  controllerNumber : yup.string().optional().nullable(),
  converterNumber : yup.string().optional().nullable(),
  chassisNumber : yup.string().required("Please enter Chassis Number").nullable(),
  state : yup.string().required("Please enter State").nullable(),
  city : yup.string().required("Please enter City").nullable(),
  manufactureYear : yup.string().required("Please enter Manufacture Year").nullable(),
  vhImage : yup.string().required("Please choose bike image").nullable(),
  rcImageF : yup.string().optional().nullable(),
  rcImageB : yup.string().optional().nullable(),
  insuranceImage : yup.string().optional().nullable(),
  invoiceImage : yup.string().required("Please choose Invoice Image").nullable(),
  wareHouseId : yup.mixed().required("Please enter Ware House Id").nullable(),
  insuranceNumber : yup.string().required("Please enter Insurance Number").nullable(),
  //rcNumber : yup.string().required("Please enter Rc Number").nullable(),
});
// _NV_
