import Functions from "utils/functions.utils";
import instance from "../utils/axios.utils";
import { setManyOrganization, setOrganization } from "utils/redux.utils";

const Organization = {
  createOrganization: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "clients/onboard";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  editOrganization: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "clients/onBoard";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  getOrganization: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "clients/getClients";
        const response = await instance().get(url, { params: id });
        setOrganization(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getAllocatedDriver: (cid:string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `clients/available-drivers/stats/client/${cid}`;
        const response = await instance().get(url);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getManyOrganization: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "users/sa/list";
        const response = await instance().get(url, { params: query });
        setManyOrganization(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  clientStats: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "dashboard/client/stats";
        const response = await instance().get(url, { params: query });
        setManyOrganization(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  approveOrganization: async (id) => {
    try {
      const url = `users/sa/approve?userId=${id}`;
      const response = await instance().patch(url);
      return response.data;
    } catch (error) {
      throw Functions.modelError(error);
    }
  },

  rejectOrganization: async (id) => {
    try {
      const url = `users/sa/reject?userId=${id}`;
      const response = await instance().patch(url);
      return response.data;
    } catch (error) {
      throw Functions.modelError(error);
    }
  },
  deleteOrganization: (payload) => {
    let promise = new Promise((resolve, reject) => {
      let url = "clients/sa/drop";
      instance()
        .delete(url, { data: payload })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  exportOrg: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "clients/getClients?export=1";
        const response = await instance().get(url);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
};

export default Organization;
