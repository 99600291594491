import { useNavigate } from "react-router-dom";
import {
  useSetState,
  toastifyError,
  createExcelFile,
} from "utils/functions.utils";
import "./warehouse.screen.scss";
import Search from "common_components/ui/search/search.ui";
import Button from "common_components/ui/button/button.ui";
import Pagination from "components/pagination/pagination.component";
import { Models } from "utils/imports.utils";
import { useEffect } from "react";
import Assets from "imports/assets.import";
import _ from "lodash";
import Table from "components/table/table.component";
import pako from "pako";
import { Buffer } from "buffer";
import CustomModal from "common_components/ui/modal/modal.component";
import DeletePopup from "components/delete_popup/delete_popup.component";
import AccessControlWrapper from "common_components/access_control.wrapper.hoc";

export default function Warehouse() {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  const [state, setState] = useSetState({
    data: [],
    search: "",
    openModal: false,
    viewModal: false,
    deleteModal: false,
    loading: false,
    currentPage: 1,
    totalItems: 0,
    toggleFilter: false,
  });

  const thead = [
    { head: "Date", key: "createdAt", type: "date-only" },
    { head: "Name", key: "name", type: "string" },
    {
      head: "Location",
      key: "address.fullAddress",
      type: "string",
      isNested: true,
    },
    { head: "Warehouse ID", key: "whId", type: "string" },
    {
      head: "Security Incharge",
      key: "securityPersonDetails[0].name",
      type: "string",
      isNested: true,
    },
    {
      head: "Mobile",
      key: "securityPersonDetails[0].number",
      type: "string",
      isNested: true,
    },
  ];
  const GetManyData = async () => {
    try {
      let res: any;
      const body: any = {
        page: state.currentPage,
        pageSize: 10,
      };
      if (state.search.length > 0) {
        body.search = state.search;
      
      }
      res = await Models.warehouse.getManyWarehouse(body);
      setState({ data: res?.data, loading: false, totalItems: res?.count });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  useEffect(() => {
    GetManyData();
  }, [state.search, state.currentPage]);

  const DeleteWarehouse = async () => {
    try {
      await Models.warehouse.deleteWarehouse({
        ids: [state.id],
        target: "delete",
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: false,
      });
      setState({ id: "", deleteModal: false });
      GetManyData();
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const handlePageChange = (page: number) => {
    setState({ currentPage: page });
  };
  const handleView = (data) => {
    navigate(`/warehouse/view/${data?._id}`);
  };
  const handleCharger = (data) => {
    navigate(`/warehouse/charger/${data?._id}`);
  };
  const handlePower = (data) => {
    navigate(`/warehouse/power/${data?._id}`);
  };
  const handleDelete = (data) => {
    setState({ id: data._id, deleteModal: true });
  };
  const handleExport = async () => {
    const res: any = await Models.warehouse.exportWarehouse();
    const compressedString = res.data;
    const compressedBuffer = Buffer.from(compressedString, "base64");
    const base64String = pako.ungzip(compressedBuffer, { to: "base64" });
    createExcelFile(base64String);
  };
  return (
    <div className="store_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="head h3">Warehouse</div>
        </div>
        <div className="search_wrapper">
          <Search
            value={state.search}
            onChange={(search) => setState({ search ,currentPage: 1, totalItems: 0 })}
            placeholder={""}
          />
          <div className="button_group">
            <AccessControlWrapper accessKey="WAREHOUSE_EXPORT">
              <Button
                value="+ Export"
                onClick={() => {
                  handleExport();
                }}
              />
            </AccessControlWrapper>
            <AccessControlWrapper accessKey="WAREHOUSE_ADD">
              <Button
                value="Add Warehouse"
                onClick={() => {
                  navigate(`/warehouse/add`);
                }}
              />
            </AccessControlWrapper>
          </div>
        </div>
      </div>
      <div className="storetable">
        <Table
          data={state.data}
          loading={state.loading}
          theads={thead}
          link="store"
          actions={[
            {
              key: "WAREHOUSE_CHARGING",
              icon: "charger",
              onClick: handlePower,
            },
            {
              key: "WAREHOUSE_POWER_BILL",
              icon: "power",
              onClick: handleCharger,
            },
            {
              key: "WAREHOUSE_VIEW",
              icon: "view",
              onClick: handleView,
            },
            {
              key: "WAREHOUSE_DELETE",
              icon: "delete",
              onClick: handleDelete,
            },
          ]}
        />
      </div>
      <div className="driver_pagination">
        <Pagination
          totalItems={state.totalItems}
          itemsPerPage={10}
          currentPage={state.currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      >
        <DeletePopup
          onPress={DeleteWarehouse}
          onCancel={() => setState({ deleteModal: false })}
        />
      </CustomModal>
    </div>
  );
}
